import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  locale: 'ru',
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'LOCAL_SETTINGS_SET_LOCALE': {
      return state.set('locale', payload.locale);
    }

    default:
      return state;
  }
};
