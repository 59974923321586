import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const propTypes = {
  children: PropTypes.node,
};

const PanelBottom = ({ children }) => {
  return (
    <div className="wrapper-main-bottom">
      <div className="wrapper-main-bottom-view">{children}</div>
    </div>
  );
};

PanelBottom.propTypes = propTypes;

export default PanelBottom;
