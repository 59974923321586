import React from 'react';
import PropTypes from 'prop-types';
import './ButtonHeader.css';

const styleType = type => {
  switch (type) {
    default:
      return 'header_sub_menu_button_color_default';
  }
};

const ButtonHeader = ({ icon, title, type }) => {
  const newClass = styleType(type);
  return (
    <a href="#">
      <div className={`header_sub_menu_button ${newClass}`}>
        {type ? <>{icon || null}</> : null}
        {title.length ? title : null}
      </div>
    </a>
  );
};

ButtonHeader.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['default']),
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
ButtonHeader.defaultProps = {
  title: '',
  type: 'default',
  icon: null,
};
export default ButtonHeader;
