let configLocal;

try {
  // eslint-disable-next-line
  configLocal = require('../config-local.json');
} catch (error) {
  configLocal = {};
}

const config = {
  apiUrl: 'https://ilh.pw/api/api.php',
};

export default { ...config, ...configLocal };
