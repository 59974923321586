import apiInstance from './tools/apiInstance';

export default {
  async setStatusPay(params) {
    const { url, token, PaymentId, status, amount } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      PaymentId,
      status,
      amount,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setStatusPay`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setModernPost(params) {
    const { url, token, idPost, command } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      idPost,
      command,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setModernPost`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getIdNews(params) {
    const { url, idPost } = params;
    const api = await apiInstance({ url });

    const paramsBody = {
      params: {
        idPost,
      },
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getPreviewPost`, paramsBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getG(params) {
    const { url, token, idPost } = params;
    const api = await apiInstance({ url, token, idPost });

    const paramsBody = {
      params: {
        idPost,
      },
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getG`, paramsBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getPayments(params) {
    const { url, token } = params;
    const api = apiInstance({ url, token });
    try {
      const response = await api.get(`?id=getPayments`, {
        params: {
          lang: 'ru',
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getInfo(params) {
    const { url, token } = params;
    const api = apiInstance({ url, token });
    try {
      const response = await api.get(`?id=all_my_org`, {
        params: {
          lang: 'ru',
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getMainCategoryObjects(params) {
    const { url, token } = params;
    const api = apiInstance({ url, token });
    try {
      const response = await api.get(`?id=getMainCategoryObjects`, {});
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setInfrastructureObject(params) {
    const { url, token, idCategory, data } = params;
    const api = apiInstance({ url, token });
    try {
      const postBody = {
        idCategory,
        arrayData: data,
      };
      const response = await api.post(`?id=setInfrastructureObject`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setMainCategoryObjects(params) {
    const { url, token, idCategory, data } = params;
    const api = apiInstance({ url, token });
    try {
      const postBody = {
        idCategory,
        arrayData: data,
      };
      const response = await api.post(`?id=setMainCategoryObjects`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removeMainCategoryObjects(params) {
    const { url, token, idCategory, idObject } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postBody = {
      idCategory,
      idObject,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(
        `?id=removeMainCategoryObjects`,
        postBody,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getModernPosts(params) {
    const { url, token, type } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postBody = {
      params: {
        type,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getModernPosts`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};
