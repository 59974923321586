import apiInstance from './tools/apiInstance';

export default {
  async setInfrastructure(params) {
    const { url, token, nameInfra, idInfra, index, idChangeInfra } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postBody = {
      nameInfra,
      idInfra,
      index,
      idChangeInfra,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setInfrastructure`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getInfrastructure(params) {
    const { url, token, idCity, page, idInfra } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postParams = {
      params: {
        idCity,
        page,
        idInfra,
      },
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getInfrastructure`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};
