import React from 'react';
import PropTypes from 'prop-types';

const EmptyBlock = ({ title, name, description, onClick }) => {
  const handleClick = () => {
    onClick(name);
    return false;
  };
  return (
    <>
      <div className="emptyOrganizationRequired" />
      <div className="emptyOrganizationRequiredText">
        <div>{title}</div>
        <div>{description}</div>
        <div>
          <a href="#open_modal" onClick={handleClick}>
            Подать заявку
          </a>
        </div>
      </div>
    </>
  );
};

EmptyBlock.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};

EmptyBlock.defaultProps = {
  title: '',
  name: 'empty',
  description: 'Данная услуга не подключена!',
  onClick: () => {},
};

export { EmptyBlock };
