import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { Icon } from '../Icon';
import Picker from 'emoji-picker-react';
import MaskedInput from 'react-text-mask';
import { IoMdSearch, IoMdRemoveCircle } from 'react-icons/io';
import { SketchPicker } from 'react-color';
import * as Datetime from 'react-datetime';
import './dateTime.css';
import 'moment/locale/ru';

import './Input.css';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  padding: 5px 0 5px 0;
  border: 1px solid ${(props) => props.colorBorder};
  margin-top: 5px;
  margin-bottom: 15px;
  background-color: white;
`;

class Input extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    dateFormat: PropTypes.string,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    isPaddingVertical: PropTypes.bool,
    type: PropTypes.string,
    isEmoji: PropTypes.bool,
    placeholder: PropTypes.string,
    typeText: PropTypes.oneOf([
      'default',
      'multi',
      'phone',
      'search',
      'remove',
      'color',
      'date',
      'select',
    ]),
    rows: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClickRemove: PropTypes.func,
    onSelectPress: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    value: '',
    name: 'text',
    dateFormat: 'YYYY-MM-DD',
    rows: 3,
    isPaddingVertical: false,
    error: false,
    isEmoji: false,
    errorText: '',
    type: 'text',
    placeholder: '',
    typeText: 'default',
    onChange: () => {},
    onKeyDown: () => {},
    onClickRemove: () => {},
    onSelectPress: () => {},
  };

  state = {
    isOpenColor: false,
    color: this.props.value,
    nextColor: '',
    isEmojiOpen: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleTextChange = (event) => {
    const { typeText, name, onChange } = this.props;
    if (typeText === 'date') {
      onChange(moment(event).format('YYYY-MM-DD'), name);
    } else {
      onChange(event.target.value, name);
    }
  };

  onEmojiClick = (event, emojiObject) => {
    const { value, name, onChange } = this.props;
    console.log(emojiObject.emoji);
    onChange(`${value}${emojiObject.emoji}`, name);
    this.setState((prevState) => ({
      ...prevState,
      isEmojiOpen: false,
    }));
  };

  handleInputRemove = (event) => {
    const { onClickRemove } = this.props;
    onClickRemove();
    event.preventDefault();
  };

  handleOpenColorClick = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      isOpenColor: true,
    }));
    event.preventDefault();
  };

  handleChangeComplete = (color) => {
    this.setState((prevState) => ({
      ...prevState,
      nextColor: color.hex,
    }));
  };

  handleSelectColorClick = (event) => {
    const { name, onChange } = this.props;
    const { nextColor } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      color: nextColor,
      isOpenColor: false,
    }));
    onChange(nextColor, name);
    event.preventDefault();
  };

  handleChancelColorClick = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      isOpenColor: false,
    }));
    event.preventDefault();
  };

  handleChancelColorRemoveClick = (event) => {
    const { name, onChange } = this.props;
    this.setState((prevState) => ({
      ...prevState,
      color: '',
      isOpenColor: false,
    }));
    onChange('', name);
    event.preventDefault();
  };

  handleOpenPickerEmoji = (event) => {
    this.setState((prevState) => ({
      ...prevState,
      isEmojiOpen: !prevState.isEmojiOpen,
    }));
    event.preventDefault();
  };

  render() {
    const {
      isPaddingVertical,
      rows,
      typeText,
      value,
      isEmoji,
      type,
      placeholder,
      title,
      dateFormat,
      error,
      errorText,
      onKeyDown,
      onSelectPress,
    } = this.props;
    const { isOpenColor, nextColor, color, isEmojiOpen } = this.state;
    const borderStyle = error ? '#e74c3c' : '#b1b9c3';
    if (
      typeText === 'default' ||
      typeText === 'phone' ||
      typeText === 'search' ||
      typeText === 'remove' ||
      typeText === 'color' ||
      typeText === 'date' ||
      typeText === 'select'
    ) {
      return (
        <>
          {title.length ? (
            <div style={isPaddingVertical ? { paddingTop: 15 } : {}}>
              <span>{title}</span>
            </div>
          ) : null}

          <Wrapper
            style={isPaddingVertical ? { marginBottom: 15 } : {}}
            className="formInput"
            colorBorder={borderStyle}>
            {typeText === 'select' ? (
              <div
                className="formInput_view formInput_view_select"
                onClick={onSelectPress}>
                <div className="formInput_select">
                  {String(value).length ? value : placeholder}
                </div>
              </div>
            ) : null}
            {typeText === 'remove' ? (
              <div className="formInput_view">
                <div className="formInput_input">
                  <input
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onKeyDown={onKeyDown}
                    onChange={this.handleTextChange}
                  />
                </div>
                <div className="formInput_icon_remove">
                  <a href="#" onClick={this.handleInputRemove}>
                    <IoMdRemoveCircle size={25} color="red" />
                  </a>
                </div>
              </div>
            ) : null}
            {typeText === 'search' ? (
              <div className="formInput_view">
                <div className="formInput_icon">
                  <IoMdSearch size={25} />
                </div>
                <div className="formInput_input">
                  <input
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onKeyDown={onKeyDown}
                    onChange={this.handleTextChange}
                  />
                </div>
              </div>
            ) : null}
            {typeText === 'default' ? (
              <>
                <input
                  type={type}
                  placeholder={placeholder}
                  value={value}
                  onKeyDown={onKeyDown}
                  onChange={this.handleTextChange}
                />
              </>
            ) : null}
            {typeText === 'date' ? (
              <>
                <Datetime
                  dateFormat={dateFormat}
                  timeFormat={false}
                  value={value}
                  onChange={this.handleTextChange}
                />
              </>
            ) : null}
            {typeText === 'color' ? (
              <>
                {isOpenColor ? (
                  <div className="formInput_color_modal_color">
                    <div>
                      <SketchPicker
                        color={nextColor}
                        onChangeComplete={this.handleChangeComplete}
                      />
                      <div className="formInput_color_modal_color_btn">
                        <a href="#" onClick={this.handleSelectColorClick}>
                          Выбрать
                        </a>
                        <a
                          href="#"
                          onClick={this.handleChancelColorRemoveClick}>
                          Очистить
                        </a>
                        <a href="#" onClick={this.handleChancelColorClick}>
                          Отмена
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="formInput_color">
                  <input
                    type={type}
                    placeholder={placeholder}
                    value={color}
                    onKeyDown={onKeyDown}
                    onClick={this.handleOpenColorClick}
                    onChange={() => {}}
                  />
                  <div
                    className="formInput_color_code"
                    style={{ backgroundColor: color }}></div>
                </div>
              </>
            ) : null}
            {typeText === 'phone' ? (
              <>
                <div className="form-control-phone">+7</div>
                <MaskedInput
                  mask={[
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  className="form-control"
                  placeholder="(123) 123-12345"
                  guide={true}
                  id="my-input-id"
                  value={value}
                  onBlur={() => {}}
                  onChange={this.handleTextChange}
                />
              </>
            ) : null}
            {error && errorText.length ? (
              <span className="formInputError">{errorText}</span>
            ) : null}
            {isEmoji ? (
              <div className="rightIcon">
                <a href="#" onClick={this.handleOpenPickerEmoji}>
                  <Icon icon="emoji" size={25} />
                </a>
              </div>
            ) : null}
          </Wrapper>
          {isEmojiOpen ? (
            <div className="input_emoji">
              <Picker onEmojiClick={this.onEmojiClick} />
            </div>
          ) : null}
        </>
      );
    }

    return (
      <>
        {title.length ? <span>{title}</span> : null}
        <Wrapper className="formInput" colorBorder="silver">
          <textarea
            rows={rows}
            placeholder={placeholder}
            value={value}
            onChange={this.handleTextChange}
          />
          {isEmoji ? (
            <div className="rightIcon">
              <a href="#" onClick={this.handleOpenPickerEmoji}>
                <Icon icon="emoji" size={25} />
              </a>
            </div>
          ) : null}
        </Wrapper>
        {isEmojiOpen ? (
          <div className="input_emoji">
            <Picker onEmojiClick={this.onEmojiClick} />
          </div>
        ) : null}
      </>
    );
  }
}

export default Input;
