import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoIosStar, IoIosStarOutline, IoIosStarHalf } from 'react-icons/io';
import './style.css';

const propTypes = {
  isTextStar: PropTypes.bool,
  size: PropTypes.number,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  size: 20,
  isTextStar: true,
};

const Star = ({ count, isTextStar, size }) => {
  const [star, setStar] = useState([]);

  const getNewStar = () => {
    const maxCount = 5;
    let newStarArray = [];
    for (let i = 0; i < maxCount; i += 1) {
      let numberStar = 0;
      if (i + 1 <= Number(count)) {
        numberStar = 1;
      } else {
        const changeCount = Number(count) - i;
        if (changeCount > 0) {
          numberStar = 0.5;
        }
      }
      newStarArray = [...newStarArray, [numberStar]];
    }
    setStar(newStarArray);
  };

  useEffect(() => {
    getNewStar();
  }, []);

  return (
    <div className="star-wrapper-view">
      {star.map((i, index) => {
        return (
          <div key={`star${index}`}>
            {Number(i) > 0 && Number(i) === 1 ? <IoIosStar size={size} color="#e67e22" /> : null}
            {Number(i) > 0 && Number(i) < 1 ? <IoIosStarHalf size={size} color="#e67e22" /> : null}
            {Number(i) === 0 ? <IoIosStarOutline size={size} color="#e67e22" /> : null}
          </div>
        );
      })}
      {isTextStar ? <div className="star-wrapper-view-text">{`(${count})`}</div> : null}
    </div>
  );
};

Star.propTypes = propTypes;
Star.defaultProps = defaultProps;

export default Star;
