import { put, call, delay, select, takeEvery, takeLatest } from 'redux-saga/effects';
import posterActions from '../redux/actions/poster';
import posterApi from '../../api/poster';
import errorsActions from '../redux/actions/errors';
import { userTokenSelector } from '../../selectors/user';

const { errorIsInternet } = errorsActions;

function* setPoster(action) {
  const { success, failure, fulfill } = posterActions.setPoster;
  const {
    deferred,
    idCategory,
    posterTitle,
    posterLocation,
    posterDescription,
    dateToTime,
    idFirm,
    idPoster,
  } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(posterApi.setPoster, {
      token,
      idCategory,
      posterTitle,
      posterLocation,
      posterDescription,
      dateToTime,
      idFirm,
      idPoster,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* getPoster(action) {
  const { success, failure, fulfill } = posterActions.getPoster;
  const { deferred, idFirm } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(posterApi.getPoster, {
      token,
      idFirm,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* removePoster(action) {
  const { success, failure, fulfill } = posterActions.removePoster;
  const { deferred, idPoster, idFirm } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(posterApi.removePoster, {
      token,
      idPoster,
      idFirm,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeEvery(posterActions.setPoster.TRIGGER, setPoster);
  yield takeLatest(posterActions.getPoster.TRIGGER, getPoster);
  yield takeLatest(posterActions.removePoster.TRIGGER, removePoster);
}
