import { fromJS } from 'immutable';
import adminInfoActions from '../actions/adminInfo';

const { getMobileInfo } = adminInfoActions;

const DEFAULT_INFO = {
  loading: false,
  data: [],
  error: false,
};

const INITIAL_STATE = fromJS({
  info: DEFAULT_INFO,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    /**
     * getMobileInfo
     */

    case getMobileInfo.TRIGGER: {
      return state.setIn(['info', 'loading'], true).setIn(['info', 'error'], false);
    }
    case getMobileInfo.SUCCESS: {
      const { data } = payload;
      return state.setIn(['info', 'data'], fromJS(data));
    }
    case getMobileInfo.FAILURE: {
      return state.setIn(['info', 'loading'], false).setIn(['info', 'error'], true);
    }
    case getMobileInfo.FULFILL: {
      return state.setIn(['info', 'loading'], false);
    }

    default:
      return state;
  }
};
