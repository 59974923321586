export default {
  NEW_TASK_STEP: 'newTask/TASK_STEP',
  NEW_TASK_DESCRIPTION_TASK: 'newTask/DESCRIPTION_TASK',
  NEW_TASK_CHANGE_PARAMETER_CAR: 'newTask/PARAMETER_CAR',
  NEW_TASK_CHANGE_PARAMETER_HOUR_WORK: 'newTask/CHANGE_PARAMETER_HOUR_WORK',
  NEW_TASK_ADD_ADDRESS_ID: 'newTask/ADD_ADDRESS_ID',
  NEW_TASK_CHANGE_ADDRESS: 'newTask/CHANGE_ADDRESS',
  NEW_TASK_CHANGE_OPTION_TASK: 'newTask/CHANGE_OPTION_TASK',
  NEW_TASK_ENTER: 'newTask/ENTER',
  NEW_TASK_CLEAR: 'newTask/CLEAR',

  ERRORS_IS_CONNECTION_INTERNET: 'errors/IS_CONNECTION_INTERNET',
};
