import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  margin: 0;
  padding: 0;
`;

const NumberText = styled.span`
  position: absolute;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  font-size: 0.8em;
  width: 15px;
  height: 15px;
  top: -5px;
  right: -6px;
  background-color: red;
  border-radius: 7.5px;
  text-align: center;
  color: #fff;
  z-index: 99;
`;

class Badges extends PureComponent {
  static propTypes = {
    number: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const { children, number, size } = this.props;
    const styleWrapper = { height: size };
    return (
      <Wrapper style={styleWrapper}>
        {children}
        {number > 0 ? <NumberText>{number}</NumberText> : null}
      </Wrapper>
    );
  }
}

export default Badges;
