import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './ButtonSmall.css';

class ButtonSmall extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.oneOf(['default', 'primary']),
    onClick: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    type: 'default',
    onClick: () => {},
  };

  handleSubmit = event => {
    // TODO: handleSubmit
    event.preventDefault();
  };

  render() {
    const { title, type, onClick } = this.props;
    const btnType = `btn-small-${type}`;
    return (
      <button onClick={onClick} className={`btn-small ${btnType}`}>
        {title}
      </button>
    );
  }
}

export default ButtonSmall;
