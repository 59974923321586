import { fromJS } from 'immutable';
import actionTypes from '../actionTypes';

const {
  NEW_TASK_STEP,
  NEW_TASK_DESCRIPTION_TASK,
  NEW_TASK_CHANGE_PARAMETER_CAR,
  NEW_TASK_CHANGE_PARAMETER_HOUR_WORK,
  NEW_TASK_ADD_ADDRESS_ID,
  NEW_TASK_CHANGE_ADDRESS,
  NEW_TASK_CHANGE_OPTION_TASK,
  NEW_TASK_ENTER,
  NEW_TASK_CLEAR,
} = actionTypes;

const OPTION_NEW_TASK = {
  task: '',
  address: '',
  addressId: '',
  parameterHourWork: '',
  parameterCar: '',
  descriptionTask: '',
};

const INITIAL_STATE = fromJS({
  stepNewTask: 0,
  stepTask: 0,
  newTask: OPTION_NEW_TASK,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case NEW_TASK_DESCRIPTION_TASK: {
      const { value } = payload;
      return state.setIn(['newTask', 'descriptionTask'], value);
    }
    case NEW_TASK_CHANGE_PARAMETER_CAR: {
      const { option } = payload;
      return state.setIn(['newTask', 'parameterCar'], option);
    }
    case NEW_TASK_CHANGE_PARAMETER_HOUR_WORK: {
      const { option } = payload;
      return state.setIn(['newTask', 'parameterHourWork'], option);
    }
    case NEW_TASK_ADD_ADDRESS_ID: {
      const { id } = payload;
      return state.setIn(['newTask', 'addressId'], id);
    }
    case NEW_TASK_CHANGE_ADDRESS: {
      const { value } = payload;
      return state.setIn(['newTask', 'address'], value);
    }
    case NEW_TASK_CHANGE_OPTION_TASK: {
      const { option } = payload;
      return state.setIn(['newTask', 'task'], option);
    }
    case NEW_TASK_ENTER: {
      const { step } = payload;
      return state.set('stepTask', step);
    }
    case NEW_TASK_STEP: {
      const { step } = payload;
      return state.set('stepNewTask', step);
    }
    case NEW_TASK_CLEAR:
      return state.set('stepNewTask', 0).set('stepTask', 0);
    default:
      return state;
  }
};
