import React, { PureComponent } from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { fadeIn, fadeOut } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import './style.css';

const styles = {
  bounce: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn'),
  },
  outBounce: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeOut, 'fadeOut'),
  },
};

export default class InfoAlert extends PureComponent {
  state = {
    value: '',
    isShow: false,
    isStartShow: false,
  };

  componentWillUnmount() {
    clearTimeout(this.setTime);
  }

  openAlert = (value, error = 'default') => {
    const { isStartShow } = this.state;
    this.setState({ isShow: true, value, type: error }, () => {
      if (!isStartShow) {
        this.setTime = setTimeout(() => {
          this.setState(
            {
              isStartShow: true,
            },
            () => {
              this.setTime = setTimeout(() => {
                this.setState({
                  isShow: false,
                  isStartShow: false,
                });
              }, 1000);
            },
          );
        }, 1500);
      } else {
        clearTimeout(this.setTime);
        this.setState({
          isStartShow: false,
          isShow: false,
        });
        this.openAlert(value);
      }
    });
  };

  getTypeClass = () => {
    const { type } = this.state;
    switch (type) {
      case 'error':
        return 'info_alert_wrapper_error';
      default:
        return 'info_alert_wrapper_success';
    }
  };

  render() {
    const { isShow, value, isStartShow } = this.state;

    const styleBackground = this.getTypeClass();

    if (isShow) {
      return (
        <StyleRoot>
          <div
            style={!isStartShow ? styles.bounce : styles.outBounce}
            className={`info_alert_wrapper ${styleBackground}`}
          >
            <div className="info_alert_wrapper_view">
              <div className="info_alert_wrapper_view_icon">
                <IoIosInformationCircleOutline size={30} />
              </div>
              <div className="info_alert_wrapper_view_description">{value}</div>
            </div>
          </div>
        </StyleRoot>
      );
    }
    return null;
  }
}
