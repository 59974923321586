import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import mainActions from '../redux/actions/main';
import mainApi from '../../api/main';
import errorsActions from '../redux/actions/errors';
import { userTokenSelector } from '../../selectors/user';

const { errorIsInternet } = errorsActions;

function* getInfo(action) {
  const { success, failure, fulfill } = mainActions.getInfo;
  const { deferred } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(mainApi.getInfo, { token });
    const { result } = response.data;
    const data = { data: result };
    yield put(success(data));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    let status = {};
    if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeLatest(mainActions.getInfo.TRIGGER, getInfo);
}
