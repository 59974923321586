import { createRoutine } from 'redux-saga-routines';

const setNews = createRoutine('NEWS_SET_NEW');
const getNews = createRoutine('NEWS_GET_NEW');
const removeNews = createRoutine('NEWS_REMOVE');

export default {
  setNews,
  getNews,
  removeNews,
};
