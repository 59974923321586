import { put, call, delay, select, takeEvery } from 'redux-saga/effects';
import infraActions from '../redux/actions/infra';
import infraApi from '../../api/infra';
import errorsActions from '../redux/actions/errors';
import { userTokenSelector } from '../../selectors/user';

const { errorIsInternet } = errorsActions;

function* setInfrastructure(action) {
  const { success, failure, fulfill } = infraActions.setInfrastructure;
  const { deferred, nameInfra, idInfra, index, idChangeInfra } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(infraApi.setInfrastructure, {
      token,
      nameInfra,
      idInfra,
      index,
      idChangeInfra,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* getInfrastructure(action) {
  const { success, failure, fulfill } = infraActions.getInfrastructure;
  const { deferred, idCity, page, idInfra } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(infraApi.getInfrastructure, {
      token,
      idCity,
      page,
      idInfra,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success());

    if (deferred) {
      deferred.resolve(data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeEvery(infraActions.setInfrastructure.TRIGGER, setInfrastructure);
  yield takeEvery(infraActions.getInfrastructure.TRIGGER, getInfrastructure);
}
