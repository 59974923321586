import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowDown } from 'react-icons/io';
import Loader from '../Loader';
import './style.css';

const propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEmpty: PropTypes.bool,
  textEmpty: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  isSubTitle: PropTypes.bool, // в параметр data [{id, title, subTitle}]
  isFullDataObject: PropTypes.bool, // в параментр data [{id, title, data}] - data -> можно передавать полный объект
  isChancel: PropTypes.bool,
  typePosition: PropTypes.oneOf(['left', 'right']),
  subWidth: PropTypes.number,
  isSearch: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  notSearching: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  extraStyle: PropTypes.object,
  data: PropTypes.array,
  onChange: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onClearAllPress: PropTypes.func,
};

const defaultProps = {
  title: '',
  isEmpty: false,
  isSubTitle: false,
  isFullDataObject: false,
  textEmpty: 'Не имеет значения',
  defaultValue: 'Не выбрано',
  isSaving: false,
  isChancel: false,
  extraStyle: {},
  width: 250,
  loading: false,
  error: false,
  name: '',
  typePosition: 'left',
  subWidth: 370,
  data: [{ id: 0, title: 'Пример отображения списка' }],
  searchPlaceholder: 'Начните вводить текст',
  notSearching: 'Нет результатов',
  isSearch: false,
  onChange: () => {},
  onChangeSearch: () => {},
  onClearAllPress: () => {},
};

const SelectItems = ({
  data,
  width,
  subWidth,
  title,
  defaultValue,
  extraStyle,
  onChange,
  textEmpty,
  isEmpty,
  typePosition,
  name,
  error,
  isSearch,
  searchPlaceholder,
  onChangeSearch,
  loading,
  notSearching,
  isSubTitle,
  isFullDataObject,
  onClearAllPress,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [valueText, setValueText] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const refItem = useRef(null);

  const style = {
    wrapper: {
      width,
      ...extraStyle,
    },
    content: {
      width,
      marginTop: 5,
    },
    subWrapper: {
      width: subWidth,
    },
  };

  const handleClickOutside = (event) => {
    const parentElement = document.getElementById(
      `select_items_wrapper_id${name}`,
    );
    const { target } = event;
    if (parentElement.contains(target)) {
      //
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  });

  const handleOpenClick = (event) => {
    setIsOpen(!isOpen);
    setIsUpdate(!isUpdate);
    event.preventDefault();
  };

  const handleSelectedItem = (id, value, subData) => {
    setIsOpen(false);
    setValueText(value);
    if (!isFullDataObject) {
      onChange(id, value);
    } else {
      onChange(id, value, subData);
    }
  };

  const onSelectedItemChange = (event) => {
    handleSelectedItem(0, '');
    event.preventDefault();
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    onChangeSearch(event.target.value);
  };

  const handleClearAllPress = (e) => {
    onClearAllPress();
    setValueText('');
    setSearchValue('');
    e.preventDefault();
  };

  const typePositionStyle =
    typePosition === 'left'
      ? 'select_sub_items_left'
      : 'select_sub_items_right';

  const errorStyle = error
    ? 'select_items_wrapper_error'
    : 'select_items_wrapper_success';

  const rightTo = refItem.current ? refItem.current.clientWidth - subWidth : 0;
  const typePositionRightStyle =
    typePosition === 'right'
      ? { ...style.subWrapper, marginLeft: rightTo }
      : style.subWrapper;

  return (
    <div style={style.wrapper} className="select_items_content">
      {title.length ? <div>{title}</div> : null}
      <div
        style={style.content}
        id={`select_items_wrapper_id${name}`}
        className={`select_items_wrapper ${errorStyle}`}
        ref={refItem}>
        <a
          className="select_items_wrapper_a"
          href="#"
          onClick={handleOpenClick}>
          <div className="select_items_wrapper_view">
            <div className="body">
              {valueText.length ? valueText : defaultValue}
            </div>

            <div className="icon">
              <IoIosArrowDown size={25} color="#828282" />
            </div>
          </div>
        </a>
        {isOpen ? (
          <div
            style={typePositionRightStyle}
            className={`select_sub_items_wrapper ${typePositionStyle}`}>
            {isEmpty ? (
              <a
                className="select_sub_items_wrapper_a"
                href="#"
                onClick={onSelectedItemChange}>
                <div className={`selected`}>{textEmpty}</div>
              </a>
            ) : null}
            {isSearch ? (
              <>
                <input
                  type="text"
                  placeholder={searchPlaceholder}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
                <div className="select-items-clear-view">
                  <a
                    className="select-items-clear-all"
                    href="#"
                    onClick={handleClearAllPress}>
                    Очистить
                  </a>
                </div>
              </>
            ) : null}
            {loading ? (
              <div className="loader">
                <Loader size={20} type="black" />
              </div>
            ) : (
              <>
                {data.length ? (
                  <>
                    {data.map((item, index) => {
                      const onSelectedItem = (event) => {
                        if (!isFullDataObject) {
                          handleSelectedItem(item.id, item.title);
                        } else {
                          handleSelectedItem(item.id, item.title, item.data);
                        }
                        event.preventDefault();
                      };
                      const startIndex = isEmpty ? -1 : 0;
                      const styleSubItems =
                        index > startIndex ? 'top_border' : '';
                      return (
                        <a
                          className="select_sub_items_wrapper_a"
                          key={item.id}
                          href="#"
                          onClick={onSelectedItem}>
                          <div className={`selected ${styleSubItems}`}>
                            {item.title}
                            {isSubTitle &&
                            item.subTitle &&
                            item.subTitle.length ? (
                              <div className="sub-title">{item.subTitle}</div>
                            ) : null}
                          </div>
                        </a>
                      );
                    })}
                  </>
                ) : (
                  <div className="notSearching">{notSearching}</div>
                )}
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

SelectItems.propTypes = propTypes;
SelectItems.defaultProps = defaultProps;

export default SelectItems;
