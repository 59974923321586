import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderHistoryMap from './HeaderHistoryMap';
import './HeaderSubMenu.css';

const HeaderSubMenu = ({ headerButtons, title, children, noChildren, subTitle, logotype }) => {
  return (
    <>
      {headerButtons.length ? (
        <div className="header_sub_menu">
          <div className="header_sub_menu_btn">
            <div className="header_sub_menu_btn_view">
              {headerButtons.map((btn, index) => {
                return <div key={index}>{btn.component}</div>;
              })}
            </div>
          </div>
        </div>
      ) : null}
      {logotype.length ? (
        <>
          <HeaderHistoryMap />
          <div className="header_sub_menu_left_logotype">
            <div>
              <img className="header_sub_menu_left_logotype_view" src={logotype} />
            </div>
            <div>
              <div className="header_sub_menu_title_is_logotype">{title.toUpperCase()}</div>
              {subTitle.length ? (
                <div className="header_sub_menu_sub_title_is_logotype">{subTitle}</div>
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <HeaderHistoryMap />
          <div className="header_sub_menu_title">{title.toUpperCase()}</div>
          {subTitle.length ? <div className="header_sub_menu_sub_title">{subTitle}</div> : null}
        </>
      )}
      {!noChildren ? (
        <div className="header_sub_menu_children">{children}</div>
      ) : (
        <div className="header_sub_menu_no_children" />
      )}
    </>
  );
};

HeaderSubMenu.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  noChildren: PropTypes.bool,
  logotype: PropTypes.string,
  headerButtons: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
HeaderSubMenu.defaultProps = {
  title: '',
  subTitle: '',
  logotype: '',
  noChildren: false,
  headerButtons: [],
  children: () => null,
};
export default HeaderSubMenu;
