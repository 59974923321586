import { createRoutine } from 'redux-saga-routines';

const setPoster = createRoutine('POSTER_SET_NEW');
const getPoster = createRoutine('POSTER_GET_ALL');
const removePoster = createRoutine('POSTER_REMOVE');

export default {
  setPoster,
  getPoster,
  removePoster,
};
