import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Input';
import Loader from '../Loader';
import { InputItem } from '../InputItem';
import apiInstance from '../../../api/tools/apiInstance';
import './style.css';

const propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  error: PropTypes.bool,
  value: PropTypes.string,
  token: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultProps = {
  title: '',
  token: '',
  error: false,
  value: '',
  onChange: () => {},
};

const InputItemStreet = ({ token, error, onChange, value, title }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [findCity, setFindCity] = useState('');

  const getFindCity = async () => {
    const api = apiInstance({ token });
    setLoading(true);
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=find_city_street`, {
        params: {
          findText: findCity,
          lang: 'ru',
        },
      });
      const { result } = response.data;
      if (result) {
        setData(result);
      }
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const handleFindChange = async text => {
    setFindCity(text);
    if (findCity.length > 3) {
      try {
        await getFindCity({ findCity, deferred: true });
      } catch (e) {}
    }
  };

  const handleCitySelect = (id, city) => {
    setIsShow(false);
    onChange(id, city);
  };

  const handleItemCityOpen = () => {
    setIsShow(true);
  };
  const handleItemCityClose = () => {
    setIsShow(false);
  };
  return (
    <InputItem
      title={title}
      error={error}
      value={value}
      onOpen={handleItemCityOpen}
      onClose={handleItemCityClose}
      isShow={isShow}
    >
      <Input
        typeText="search"
        title="Найти улицу"
        placeholder="Центральная"
        value={findCity}
        onChange={handleFindChange}
      />
      <div className="input_item_city">
        {loading ? <Loader type="black" size={25} /> : null}
        {data.map(item => {
          const onSelect = event => {
            handleCitySelect(
              item.id,
              `${item.cityName}, ${item.nameTypeStreet} ${item.nameStreet}`,
            );
            event.preventDefault();
          };
          return (
            <div key={item.id}>
              <a className="input_item_city_a" href="#" onClick={onSelect}>
                {item.cityName}, {item.nameTypeStreet} {item.nameStreet}
              </a>
            </div>
          );
        })}
      </div>
    </InputItem>
  );
};

InputItemStreet.propTypes = propTypes;
InputItemStreet.defaultProps = defaultProps;

export default InputItemStreet;
