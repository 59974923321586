import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import cityActions from '../redux/actions/city';
import cityApi from '../../api/city';
import errorsActions from '../redux/actions/errors';
import { userTokenSelector } from '../../selectors/user';

const { errorIsInternet } = errorsActions;

function* setStreet(action) {
  const { success, failure, fulfill } = cityActions.setStreet;
  const { deferred, idCity, nameTypeStreet, nameStreet, setChangeStreetId } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(cityApi.setStreet, {
      token,
      idCity,
      nameTypeStreet,
      nameStreet,
      setChangeStreetId,
    });
    const { result } = response.data;
    const data = { data: result };
    yield put(success(data));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* getStreet(action) {
  const { success, failure, fulfill } = cityActions.getStreet;
  const { deferred, idCity, page } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(cityApi.getStreet, {
      token,
      idCity,
      page,
    });
    const { result } = response.data;
    const data = { data: result, pages: response.data.max_count_page };
    yield put(success(data));

    if (deferred) {
      deferred.resolve(data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* removeStreet(action) {
  const { success, failure, fulfill } = cityActions.removeStreet;
  const { deferred, street } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(cityApi.removeStreet, {
      token,
      street,
    });
    const { result } = response.data;
    const data = { data: result };
    yield put(success(data));

    if (deferred) {
      deferred.resolve(data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeLatest(cityActions.setStreet.TRIGGER, setStreet);
  yield takeLatest(cityActions.getStreet.TRIGGER, getStreet);
  yield takeLatest(cityActions.removeStreet.TRIGGER, removeStreet);
}
