import apiInstance from './tools/apiInstance';

export default {
  async setOrganizationConfirmEmail(params) {
    const { url, unicID } = params;
    const api = apiInstance({ url });
    try {
      const postBody = {
        unicID,
      };
      const response = await api.post(
        `?id=setOrganizationConfirmEmail`,
        postBody,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setReceptionVerification(params) {
    const { url, type = '', unicCode = '', numberCode = '' } = params;
    const api = apiInstance({ url });
    try {
      const postBody = {
        type,
        unicCode,
        numberCode,
      };
      const response = await api.post(`?id=setReceptionVerification`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setUserLocation(params) {
    const { url, token, type = '', idObject, idUser } = params;
    const api = apiInstance({ url, token });
    try {
      const postBody = {
        type,
        idObject,
        idUser,
      };
      const response = await api.post(`?id=setUserLocation`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getUserAccessLocation(params) {
    const { url, token, idUser } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: { idUser },
    };
    try {
      const response = await api.get(`?id=getUserAccessLocation`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removeUserLocation(params) {
    const { url, token, idLocation, idUser } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: { idLocation, idUser },
    };
    try {
      const response = await api.get(`?id=removeUserLocation`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getReceptionInfo(params) {
    const { url, unicCode = '' } = params;
    const api = apiInstance({ url });

    const postParams = {
      params: { unicCode },
    };
    try {
      const response = await api.get(`?id=getReceptionInfo`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getAccessLocation(params) {
    const { url, token } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {},
    };
    try {
      const response = await api.get(`?id=getAccessLocation`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  /**
   *
   * Get my home
   */

  async getMyHome(params) {
    const { url, token, page } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {
        page,
      },
    };
    try {
      const response = await api.get(`?id=getMyHome`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  /**
   * isLogin
   */
  async isLogin(params) {
    const { url, token, user } = params;
    const api = apiInstance({ url, token });
    try {
      const postBody = {
        login: user,
      };
      const response = await api.post(`?id=isLogin`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  /**
   * signIn
   */
  async signIn(params) {
    const { url, user, password } = params;
    const api = apiInstance({ url });
    try {
      const postBody = {
        user,
        password,
      };
      const response = await api.post(`?id=login_in`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async sendSMS(params) {
    const { url, phone } = params;

    const api = apiInstance({ url });
    // eslint-disable-next-line no-useless-catch
    try {
      const postBody = {
        phone,
      };
      const response = await api.post(`?id=register_phone_web`, postBody, {
        params: {
          lang: 'ru',
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async sendCodeSMS(params) {
    const { url, codeSMS, phone } = params;

    const api = apiInstance({ url });
    // eslint-disable-next-line no-useless-catch
    try {
      const postBody = {
        phone,
        codeSMS,
      };
      const response = await api.post(
        `?id=register_phone_web_code_sms`,
        postBody,
        {
          params: {
            lang: 'ru',
          },
        },
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async sendNewPassword(params) {
    const { url, codeSMS, phone, newPass } = params;

    const api = apiInstance({ url });
    // eslint-disable-next-line no-useless-catch
    try {
      const postBody = {
        phone,
        codeSMS,
        newPass,
      };
      const response = await api.post(
        `?id=register_phone_web_code_sms_new_password`,
        postBody,
        {
          params: {
            lang: 'ru',
          },
        },
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setPushNotificationDispatcher(params) {
    const {
      url,
      token,
      isAll = false,
      title = '',
      idPost = '', // если открываем пост определенный
      message = '',
      arrayRayon = [],
      arrayCity = [],
      arrayStreet = [],
      image = '',
      typeData = '',
      data = '',
    } = params;

    const api = apiInstance({ url, token });
    // eslint-disable-next-line no-useless-catch
    try {
      const postBody = {
        isAll,
        title,
        message,
        idPost,
        arrayRayon,
        arrayCity,
        arrayStreet,
        typeData,
        image,
        data,
      };
      const response = await api.post(
        `?id=setNotificationDispatcher`,
        postBody,
        {
          params: {
            lang: 'ru',
          },
        },
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getRequired(params) {
    const { url, token, active = '0' } = params;

    const api = apiInstance({ url, token });
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getRequired`, {
        params: {
          lang: 'ru',
          active,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setRequiredInterwier(params) {
    const { url, token, active = '0', idRequired = 0 } = params;

    const api = apiInstance({ url, token });
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setRequiredInterwier`, {
        type: active,
        idRequired,
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removeUserRequired(params) {
    const { url, token, idRequired = 0 } = params;

    const api = apiInstance({ url, token });
    try {
      const response = await api.get(`?id=removeUserRequired`, {
        params: {
          idRequired,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getHistoryNotification(params) {
    const { url, token } = params;

    const api = apiInstance({ url, token });
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getHistoryNotification`, {
        params: {
          lang: 'ru',
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getUsers(params) {
    const { url, token, page, id_user = 0, find = '' } = params;

    const api = apiInstance({ url, token });
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getUsers`, {
        params: {
          lang: 'ru',
          page,
          id_user,
          find,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setSettingUser(params) {
    const { url, token, userName, login, password } = params;

    const api = apiInstance({ url, token });
    const propBody = {
      userName,
      login,
      password,
    };
    try {
      const response = await api.post(`?id=setSettingUser`, propBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getFeedBack(params) {
    const { url, token, page, active } = params;

    const api = apiInstance({ url, token });
    const postParams = {
      params: {
        page,
        active,
      },
    };
    try {
      const response = await api.get(`?id=getFeedBack`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setChangeStatusFeedback(params) {
    const { url, token, idObject, status } = params;

    const api = apiInstance({ url, token });
    const postBody = {
      idObject,
      status,
    };
    try {
      const response = await api.post(`?id=setChangeStatusFeedback`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setFeedBackOpen(params) {
    const { url, token, idObject } = params;

    const api = apiInstance({ url, token });
    const postBody = { idObject };
    try {
      const response = await api.post(`?id=setFeedBackOpen`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};
