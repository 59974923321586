import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CabinetMain } from '../components/CabinetMain';
import { userNameSelector, userAuthSelector, userAvatarSelector } from '../selectors/user';

const CabinetMainContainer = props => <CabinetMain {...props} />;

const mapStateToProps = state => ({
  userName: userNameSelector(state),
  auth: userAuthSelector(state),
  avatar: userAvatarSelector(state),
});

export default withRouter(connect(mapStateToProps)(CabinetMainContainer));
