import { fromJS } from 'immutable';
import actionTypes from '../actionTypes';

const { ERRORS_IS_CONNECTION_INTERNET } = actionTypes;

const INITIAL_STATE = fromJS({
  isErrorConnectInternet: false,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ERRORS_IS_CONNECTION_INTERNET: {
      return state.set('isErrorConnectInternet', payload.error);
    }

    default:
      return state;
  }
};
