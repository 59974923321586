import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import adminInfoActions from '../redux/actions/adminInfo';
import adminInfoApi from '../../api/adminInfo';
import errorsActions from '../redux/actions/errors';
import { userTokenSelector } from '../../selectors/user';

const { errorIsInternet } = errorsActions;

function* getMobileInfo(action) {
  const { success, failure, fulfill } = adminInfoActions.getMobileInfo;
  const { deferred, idCity, page } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(adminInfoApi.getMobileInfo, {
      token,
      idCity,
      page,
    });
    const { result } = response.data;
    const data = { data: result };
    yield put(success(data));

    if (deferred) {
      deferred.resolve(data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* setMainInfoBlock(action) {
  const { success, failure, fulfill } = adminInfoActions.setMainInfoBlock;
  const {
    deferred,
    dateStart,
    dateEnd,
    title,
    description,
    typeWindow,
    mainFontColor,
    mainBackgroundColor,
    mainViewFontColor,
    mainViewBackgroundColor,
    btnURLValue,
    btnValue,
  } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(adminInfoApi.setMainInfoBlock, {
      token,
      dateStart,
      dateEnd,
      title,
      description,
      typeWindow,
      mainFontColor,
      mainBackgroundColor,
      mainViewFontColor,
      mainViewBackgroundColor,
      btnURLValue,
      btnValue,
    });
    const { result } = response.data;
    const data = { data: result };
    yield put(success(data));

    if (deferred) {
      deferred.resolve(data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* setRemoveMobileInfo(action) {
  const { success, failure, fulfill } = adminInfoActions.setRemoveMobileInfo;
  const { deferred, removeId } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(adminInfoApi.setRemoveMobileInfo, {
      token,
      removeId,
    });
    const { result } = response.data;
    const data = { data: result };
    yield put(success(data));

    if (deferred) {
      deferred.resolve(data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeLatest(adminInfoActions.getMobileInfo.TRIGGER, getMobileInfo);
  yield takeLatest(adminInfoActions.setMainInfoBlock.TRIGGER, setMainInfoBlock);
  yield takeLatest(adminInfoActions.setRemoveMobileInfo.TRIGGER, setRemoveMobileInfo);
}
