import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import './style.css';

export default class Button extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf([
      'default',
      'chancel',
      'remove',
      'underline',
      'flatLine',
    ]),
    value: PropTypes.string,
    width: PropTypes.number,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    classNameNew: PropTypes.string,
  };

  static defaultProps = {
    value: '',
    type: 'default',
    width: 150,
    loading: false,
    disabled: false,
    classNameNew: '',
    onClick: () => {},
  };

  handlePress = (e) => {
    const { onClick } = this.props;
    onClick();
    e.preventDefault();
  };

  getStyleButton = (type) => {
    switch (type) {
      case 'flatLine':
        return 'form-button-flatLine';
      case 'underline':
        return 'form-button-underline form-button-underline-color';
      case 'remove':
        return 'form-button-remove form-button-remove-color';
      case 'chancel':
        return 'form-button-silver form-button-silver-color';
      default:
        return 'form-button-blue form-button-blue-color';
    }
  };

  render() {
    const { value, disabled, width, type, loading, classNameNew } = this.props;
    const styleWidth = { width, borderWidth: 1, opacity: !disabled ? 1 : 0.7 };
    const className = `form-button-view ${this.getStyleButton(type)}`;

    return (
      <a
        className="form-button-a"
        href="#"
        style={styleWidth}
        onClick={
          !disabled && !loading ? this.handlePress : (e) => e.preventDefault()
        }>
        <div className={className} style={styleWidth}>
          {loading ? (
            <div className="form-button-center-progress">
              <Loader size={20} />
            </div>
          ) : (
            <div className={`form-button-center-text ${classNameNew}`}>
              {value}
            </div>
          )}
        </div>
      </a>
    );
  }
}
