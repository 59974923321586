import apiInstance from './tools/apiInstance';

export default {
  async removeImageIsNews(params) {
    const { url, token, idImage, idNews, isAdminAccess } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postParams = {
      params: {
        idImage,
        idNews,
        isAdminAccess,
      },
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=removeImageIsNews`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setMainNews(params) {
    const { url, token, idNews } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postBody = {
      idNews,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setMainNews`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setNews(params) {
    const {
      url,
      token,
      idNews,
      idFirm,
      typeNews,
      newsTitle,
      newsDescription,
      video,
      isRegion,
      newsLink,
      isAdminAccess,
      isPushNotification = false,
      settingPushNotification = [],
    } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postBody = {
      idNews,
      idFirm,
      type_news: typeNews,
      newsTitle,
      newsDescription,
      video,
      isRegion,
      newsLink,
      isAdminAccess,
      isPushNotification,
      settingPushNotification,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setNews`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getNews(params) {
    const { url, token, idFirm, isMain, isAdminAccess } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postParams = {
      params: {
        idFirm,
        isMain,
        isAdminAccess,
      },
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getNews`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removeNews(params) {
    const { url, token, idFirm, idNews, isAdminAccess } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postBody = {
      idFirm,
      idNews,
      isAdminAccess,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=removeNews`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removePost(params) {
    const { url, token, idPost } = params;
    const api = await apiInstance({ url, token });

    const postBody = {
      idPost,
      idFirm: 0,
      isAdminAccess: '1',
    };

    try {
      const response = await api.post(`?id=removePost`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};
