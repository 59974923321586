import { put, call, delay, select, takeEvery } from 'redux-saga/effects';
import newsActions from '../redux/actions/news';
import newsApi from '../../api/news';
import errorsActions from '../redux/actions/errors';
import { userTokenSelector } from '../../selectors/user';

const { errorIsInternet } = errorsActions;

function* setNews(action) {
  const { success, failure, fulfill } = newsActions.setNews;
  const {
    deferred,
    idNews,
    idFirm,
    newsTitle,
    newsDescription,
    video,
    isRegion,
    newsLink,
    isPushNotification,
  } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(newsApi.setNews, {
      token,
      idNews,
      idFirm,
      newsTitle,
      newsDescription,
      video,
      isRegion,
      newsLink,
      isPushNotification,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* getNews(action) {
  const { success, failure, fulfill } = newsActions.getNews;
  const { deferred, idFirm, isMain = false } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(newsApi.getNews, {
      token,
      idFirm,
      isMain,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* removeNews(action) {
  const { success, failure, fulfill } = newsActions.removeNews;
  const { deferred, idFirm, idNews } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(newsApi.removeNews, {
      token,
      idFirm,
      idNews,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeEvery(newsActions.setNews.TRIGGER, setNews);
  yield takeEvery(newsActions.getNews.TRIGGER, getNews);
  yield takeEvery(newsActions.removeNews.TRIGGER, removeNews);
}
