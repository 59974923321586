import { put, call, delay, select, takeEvery } from 'redux-saga/effects';
import uploadFilesActions from '../redux/actions/uploadFiles';
import uploadFilesApi from '../../api/uploadFiles';
import errorsActions from '../redux/actions/errors';
import { userTokenSelector } from '../../selectors/user';

const { errorIsInternet } = errorsActions;

function* setFiles(action) {
  const { success, failure, fulfill } = uploadFilesActions.setFiles;
  const { deferred, dataImages, paramsConfig, idPost } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  try {
    let response;
    if (idPost === 'setMainInfoBlockFiles') {
      const infoImages = dataImages.images;
      const infoBackgroundImages = dataImages.mainBackgroundImages;
      const infoViewBackgroundImages = dataImages.mainViewBackgroundImages;
      console.log('TODO: dataImages', dataImages);
      for (let i = 0; i < 3; i += 1) {
        let goUploading = false;
        let newDataImages = [];
        let newParamsConfig = {};
        if (i === 0 && infoImages.length) {
          goUploading = true;
          newDataImages = infoImages;
          newParamsConfig = {
            params: {
              id_news: paramsConfig.params.id_news,
              vrCode: paramsConfig.params.vrCode,
              type_loader_image: 'viewImages',
            },
          };
        }
        if (i === 1 && infoBackgroundImages.length) {
          goUploading = true;
          newDataImages = infoBackgroundImages;
          newParamsConfig = {
            params: {
              id_news: paramsConfig.params.id_news,
              vrCode: paramsConfig.params.vrCode,
              type_loader_image: 'backgroundImage',
            },
          };
        }
        if (i === 2 && infoViewBackgroundImages.length) {
          goUploading = true;
          newDataImages = infoViewBackgroundImages;
          newParamsConfig = {
            params: {
              id_news: paramsConfig.params.id_news,
              vrCode: paramsConfig.params.vrCode,
              type_loader_image: 'viewBackgroundImage',
            },
          };
        }
        if (goUploading) {
          response = yield call(uploadFilesApi.setFiles, {
            token,
            dataImages: newDataImages,
            paramsConfig: newParamsConfig,
            idPost,
          });
        }
      }
    } else {
      response = yield call(uploadFilesApi.setFiles, {
        token,
        dataImages,
        paramsConfig,
        idPost,
      });
    }

    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    console.log('TODO: error', error);
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeEvery(uploadFilesActions.setFiles.TRIGGER, setFiles);
}
