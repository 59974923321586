import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, www } from '../../constants';

const HeaderMain = styled.section`
  height: 60px;
  width: 100%;
  background-color: ${colors.BACKGROUND_COLOR_HEADER0};
  border-bottom: 1px solid #dce0e6;
  z-index: 1000;
`;

const Wrapper = styled.div`
  display: table;
  width: 100%;
`;

const Container = styled.div`
  width: 120px;
`;

const LeftContainer = styled.div`
  display: table-cell;
  align-content: center;
  width: 20%;
  max-width: 120px;
  min-width: 120px;
  vertical-align: middle;
  overflow: hidden;
  padding-left: 4%;
`;

const RightContainer = styled.div`
  display: table-cell;
  width: 80%;
  height: 60px;
  vertical-align: middle;
  text-align: right;
  padding: 0;
  margin: 0;
  padding-right: 4%;
`;
const Logotype = styled.img`
  width: 100%;
`;

class Header extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const { children } = this.props;
    return (
      <HeaderMain>
        <Wrapper>
          <LeftContainer>
            <Container>
              <Link to="/">
                <Logotype src={`${process.env.PUBLIC_URL}/images/titleMobile.png`} />
              </Link>
            </Container>
          </LeftContainer>
          <RightContainer>{children}</RightContainer>
        </Wrapper>
      </HeaderMain>
    );
  }
}

export default Header;
