const getHash = (hash) => {
  let hashArray = hash.split('#');
  hashArray = hashArray.filter((i) => String(i).length);
  hashArray = hashArray.map((i) => {
    const array = i.split('=');
    const a = {
      type: array[0],
      value: array[1],
    };
    return a;
  });
  return hashArray;
};

export { getHash };
