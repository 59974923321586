import apiInstance from "./tools/apiInstance";

export default {
  async getRayon(params) {
    const { url, token, find = "" } = params;
    const api = apiInstance({ url, token });

    try {
      const response = await api.get(`?id=getRayon`, {
        params: { find },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  async getRegion(params) {
    const { url, token, find } = params;
    const api = apiInstance({ url, token });

    try {
      const response = await api.get(`?id=getRegion`, {
        params: { find },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getCity(params) {
    const {
      url,
      token,
      idRayon,
      findCity,
      longitude = "",
      latitude = "",
    } = params;
    const api = apiInstance({ url, token });

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getCity`, {
        params: { idRayon, findCity, longitude, latitude },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  async removeCity(params) {
    const { url, token, idObject, isRemoveRayon = false } = params;
    const api = apiInstance({ url, token });

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=removeCity`, {
        params: {
          idObject,
          isRemoveRayon,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  async setCity(params) {
    const {
      url,
      token,
      title,
      idItem,
      idRayon,
      idRegion,
      sh = "",
      lg = "",
      radius = 0,
      idFias = "",
    } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      idRayon,
      idRegion,
      title,
      idItem,
      sh,
      lg,
      radius,
      idFias,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setCity`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removeRayon(params) {
    const { url, token, idObject } = params;
    const api = apiInstance({ url, token });

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=removeRayon`, {
        params: {
          idObject,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  async setRayon(params) {
    const { url, token, title, idItem, services = [] } = params;
    const api = apiInstance({ url, token });

    const postBody = { idRegion: 1659, title, idItem, services };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setRayon`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  async setStreet(params) {
    const {
      url,
      token,
      idCity,
      nameTypeStreet,
      nameStreet,
      setChangeStreetId,
    } = params;
    const api = apiInstance({ url, token });

    const postBody = { idCity, nameTypeStreet, nameStreet, setChangeStreetId };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setStreet`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  async getStreet(params) {
    const { url, token, idCity, page } = params;
    const api = apiInstance({ url, token });

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getStreet`, {
        params: {
          idCity,
          page,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removeStreet(params) {
    const { url, token, street } = params;
    const api = apiInstance({ url, token });

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=removeStreet`, {
        params: {
          street,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setJSONcity(params) {
    const { url, token, idCityFias, idCity } = params;
    const api = apiInstance({ url, token });

    try {
      const response = await api.get(`?id=setJSONcity`, {
        params: {
          id_city_fias: idCityFias,
          id_city: idCity,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};
