import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonSmall } from '../../../Input';
import Loader from '../../Loader';
import './Alert.css';

const marginLeftRight = 40;
const marginTopBottom = 40;

class Alert extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.oneOf([
      'primary',
      'secondary',
      'success',
      'danger',
      'warning',
      'info',
    ]),
    resizeMode: PropTypes.oneOf(['fullScreen', 'default']),
    isShowNextStepZIndex: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    error: PropTypes.bool.isRequired,
    isHeader: PropTypes.bool,
    header: PropTypes.func,
    btnArray: PropTypes.array,
    loading: PropTypes.bool,
    btnChancelVisible: PropTypes.bool,
    btnCancel: PropTypes.string,
    disabled: PropTypes.bool,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    type: 'primary',
    resizeMode: 'default',
    children: null,
    btnArray: [],
    isShowNextStepZIndex: false,
    error: false,
    isHeader: false,
    loading: false,
    btnChancelVisible: true,
    btnCancel: 'Закрыть',
    disabled: false,
    header: () => null,
    onClose: () => {},
  };

  constructor(props) {
    super(props);
    this.divElement = React.createRef;
  }

  state = {
    height: window.innerHeight - marginTopBottom,
    width: window.innerWidth > 500 ? 500 : window.innerWidth - marginLeftRight,
    isСonfirmation: false,
    confirmationText: '',
    onOperationClick: () => {},
  };

  componentDidMount() {
    this.setState((prevState) => ({
      ...prevState,
      heightBody:
        window.innerHeight > this.divElement.clientHeight
          ? this.divElement.clientHeight
          : window.innerHeight - marginTopBottom - marginLeftRight - 100,
    }));
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const updateHeight = window.innerHeight - marginTopBottom;
    const heightBody =
      window.innerHeight > this.divElement.clientHeight
        ? this.divElement.clientHeight
        : window.innerHeight - marginTopBottom - marginLeftRight - 100;
    const updateWidth =
      window.innerWidth > 500 ? 500 : window.innerWidth - marginLeftRight;
    this.setState((prevState) => ({
      ...prevState,
      height: updateHeight,
      width: updateWidth,
      heightBody,
    }));
  };

  handleConfirmClick = () => {
    const { onOperationClick } = this.state;
    onOperationClick();
    this.setState((prevState) => ({
      ...prevState,
      isСonfirmation: false,
      confirmationText: '',
    }));
  };

  render() {
    const {
      disabled,
      title,
      children,
      btnCancel,
      type,
      btnArray,
      btnChancelVisible,
      onClose,
      resizeMode,
      loading,
      isHeader,
      header,
      isShowNextStepZIndex,
    } = this.props;
    const {
      height,
      width,
      heightBody,
      isСonfirmation,
      confirmationText,
      onOperationClick,
    } = this.state;
    const heightAlert =
      resizeMode === 'fullScreen'
        ? { height, width }
        : { width, maxHeight: height };
    const styleHeightBody =
      resizeMode === 'fullScreen' ? { maxHeight: heightBody } : {};
    if (disabled) {
      const propStyle = isShowNextStepZIndex ? 'alert_overlay_next_' : '';
      const propSubStyle = isShowNextStepZIndex
        ? 'alert_overlay_main_next_'
        : '';

      return (
        <>
          <div>
            <div className={`alert_background ${propStyle}`} />
            <div className={`alert_background_alert ${propSubStyle}`}>
              <div className="alert_modal" style={heightAlert}>
                {loading ? (
                  <div className="alert_background_alert_progress">
                    <Loader type="black" />
                  </div>
                ) : null}
                {isСonfirmation ? (
                  <>
                    <div className="alert_background_alert_progress" />
                    <div className="alert_background_alert_progress_confirm">
                      <div className="text">{confirmationText}</div>
                      <div className="btn">
                        <div>
                          <ButtonSmall
                            title="Да"
                            onClick={this.handleConfirmClick}
                          />
                        </div>
                        <div>
                          <ButtonSmall
                            title="Отмена"
                            onClick={() => {
                              this.setState((prevState) => ({
                                ...prevState,
                                isСonfirmation: false,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className={`alert_modal_title color_${type}`}>{title}</div>
                {isHeader ? (
                  <div className="alert_modal_header">{header()}</div>
                ) : null}
                <div
                  style={styleHeightBody}
                  ref={this.divElement}
                  className="alert_modal_body">
                  {children}
                </div>
                <div className="alert_modal_btn">
                  {btnArray.map((btn) => {
                    const onClickBtn = () => {
                      if (
                        btn.isСonfirmation &&
                        String(btn.confirmationText).length
                      ) {
                        this.setState(
                          (prevState) => ({
                            ...prevState,
                            isСonfirmation: true,
                            confirmationText: btn.confirmationText,
                            onOperationClick: btn.onClick,
                          }),
                          () => {},
                        );
                      } else {
                        btn.onClick();
                      }
                      // isСonfirmation
                      // confirmationText
                    };
                    return (
                      <ButtonSmall
                        key={btn.title}
                        title={btn.title}
                        onClick={onClickBtn}
                        // onClick={btn.onClick}
                      />
                    );
                  })}

                  {btnChancelVisible && (
                    <ButtonSmall title={btnCancel} onClick={onClose} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return null;
  }
}

export default Alert;
