import { all, fork } from 'redux-saga/effects';
import watcherUser from './user';
import watcherMain from './main';
import watcherOrganization from './organization';
import watcherCity from './city';
import watcherPoster from './poster';
import watcherUploadFiles from './files';
import watcherProfile from './profile';
import watcherNews from './news';
import watcherAdminInfo from './adminInfo';
import watcherInfra from './infra';

export default function* rootSaga() {
  yield all([
    yield fork(watcherUser),
    yield fork(watcherMain),
    yield fork(watcherOrganization),
    yield fork(watcherCity),
    yield fork(watcherPoster),
    yield fork(watcherUploadFiles),
    yield fork(watcherProfile),
    yield fork(watcherNews),
    yield fork(watcherAdminInfo),
    yield fork(watcherInfra),
  ]);
}
