import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { getHash } from '../../../helpers/getHash';
import './style.css';

const propTypes = {
  buttons: PropTypes.array.isRequired,
  initIndex: PropTypes.number,
  index: PropTypes.number,
  error: PropTypes.array, // [0, 1]
  onChange: PropTypes.func,
};

const defaultProps = {
  initIndex: 0,
  index: 0,
  error: [],
  onChange: () => {},
};

const Tabs = ({ buttons, index, initIndex, onChange, error }) => {
  const { hash } = useLocation();
  const history = useHistory();
  const [didMount, setDidMount] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState(initIndex);

  useEffect(() => {
    if (didMount) {
      const hashArray = getHash(hash);
      if (hashArray.length) {
        const findIndex = hashArray.findIndex((i) => i.type === 'tab');

        if (findIndex > -1) {
          onChange(hashArray[findIndex].value - 1);
          setDefaultIndex(hashArray[findIndex].value - 1);
        }
      }
      setDidMount(false);
    }
  });

  const handleIndexChange = (i) => {
    onChange(i);
    setIsUpdate(!isUpdate);
    window.history.pushState('', '', `#tab=${i + 1}`);
    setIsChange(true);
  };

  return (
    <div className="wrapper-tabs">
      <div className="wrapper-tabs-view">
        {buttons.map((btn, i) => {
          const getIndex = !isChange ? defaultIndex : index;
          let styleLink = '';
          const errorIdIndex = error.findIndex((o) => o === i);
          const isError = errorIdIndex > -1;
          if (isError) {
            styleLink =
              getIndex === i
                ? ' wrapper-tabs-view-error wrapper-tabs-view-a-select-error'
                : ' wrapper-tabs-view-error';
          } else {
            styleLink =
              getIndex === i
                ? ' wrapper-tabs-view-success wrapper-tabs-view-a-select'
                : ' wrapper-tabs-view-success';
          }

          const handleIndexButtonChange = (event) => {
            handleIndexChange(i);
            event.preventDefault();
          };
          return (
            <a
              className={`wrapper-tabs-view-a ${styleLink}`}
              key={`${btn.id}${i}`}
              href="#"
              onClick={handleIndexButtonChange}>
              {btn.title}
            </a>
          );
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
