import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]).isRequired,
  type: PropTypes.oneOf('default'),
};

const defaultProps = {
  type: 'default',
};

const InfoContent = ({ children, type }) => {
  const getStyleType = () => {
    switch (type) {
      default:
        return 'info-content-view-warning';
    }
  };
  return (
    <div className="info-content-wrapper">
      <div className={getStyleType()}>{children}</div>
    </div>
  );
};

InfoContent.propTypes = propTypes;
InfoContent.defaultProps = defaultProps;

export default InfoContent;
