import React from 'react';
import Navigation from '../../navigation';
import './App.css';

import '../MainScreen/style.css';
import '../../components/FormControl/InfoAlert/style.css';
import '../../scene/NewOrganizationScene/style.css';
import '../../components/News/style.css';
import '../../components/Organization/style.css';
import '../../components/Poster/style.css';
import '../../components/Poster/stylePosterModal.css';
import '../../components/Profile/style.css';
import '../../components/RequiredWaterReading/style.css';
import '../../components/Street/style.css';

const App = () => <Navigation />;
export default App;
