import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 200px;
  text-align: left;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  display: block;
  width: ${props => props.sizeAvatar}px;
  height: ${props => props.sizeAvatar}px;
  border-radius: 20px;
  object-fit: cover;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  color: #3498db;
  width: 100%;
  margin-left: 10px;
  overflow: hidden;
`;

const Name = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

class Avatar extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    userName: PropTypes.string,
    size: PropTypes.oneOf(['large', 'small']),
  };

  static defaultProps = {
    userName: '',
    size: 'large',
  };

  render() {
    const { url, userName, size } = this.props;
    const sizeAvatar = size === 'large' ? 35 : 20;

    return (
      <Wrapper>
        <AvatarImage sizeAvatar={sizeAvatar} src={url} />
        {userName.length ? (
          <UserName>
            <Name>{userName} </Name>
          </UserName>
        ) : null}
      </Wrapper>
    );
  }
}

export default Avatar;
