import apiInstance from './tools/apiInstance';

export default {
  async removePoster(params) {
    const { url, token, idPoster, idFirm } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postBody = {
      idPoster,
      idFirm,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=removePoster`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setChangeStatusPoster(params) {
    const { url, token, idPoster, status } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postBody = {
      idPoster,
      status,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setChangeStatusPoster`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removeImageIsPoster(params) {
    const { url, token, idPoster, type, isAdminAccess = false } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postParams = {
      params: {
        idPoster,
        type,
        isAdminAccess,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=removeImageIsPoster`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getPoster(params) {
    const { url, token, idFirm = '' } = params;
    const api = apiInstance({
      url,
      token,
    });

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getPosterV2`, {
        params: {
          idFirm,
        },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  async setPoster(params) {
    const {
      url,
      token,
      idCategory,
      posterTitle,
      posterLocation,
      posterDescription,
      dateToTime,
      idFirm,
      idPoster,
      idLocation,
      typeLocation,
      isPushNotification = false,
      settingPushNotification = [],
      isAdminAccess = false,
      phone = '',
    } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postBody = {
      idCategory,
      posterTitle,
      posterLocation,
      posterDescription,
      dateToTime,
      idFirm,
      idPoster,
      idLocation,
      typeLocation,
      isAdminAccess,
      isPushNotification,
      settingPushNotification,
      phone,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setPoster`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};
