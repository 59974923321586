import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import profileActions from '../redux/actions/profile';
import profileApi from '../../api/profile';
import errorsActions from '../redux/actions/errors';
import { userTokenSelector } from '../../selectors/user';

const { errorIsInternet } = errorsActions;

function* changeUsername(action) {
  const { success, failure, fulfill } = profileActions.changeUsername;
  const { username, password, deferred } = action.payload;

  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(profileApi.changeUsername, {
      deferred,
      token,
      username,
      password,
    });

    const { result } = response.data;

    const data = { result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}


function* changePassword(action) {
  const { success, failure, fulfill } = profileActions.changePassword;
  const { password, newPassword, deferred } = action.payload;

  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(profileApi.changePassword, {
      deferred,
      token,
      password,
      newPassword,
    });

    const { result } = response.data;

    const data = { result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeLatest(profileActions.changeUsername.TRIGGER, changeUsername);
  yield takeLatest(profileActions.changePassword.TRIGGER, changePassword);
}
