import { createRoutine } from 'redux-saga-routines';

const getFindCity = createRoutine('CITY_GET_FIND');
const setStreet = createRoutine('CITY_SET_STREET');
const getStreet = createRoutine('CITY_GET_STREET');
const removeStreet = createRoutine('CITY_REMOVE_STREET');

export default {
  getFindCity,
  setStreet,
  getStreet,
  removeStreet,
};
