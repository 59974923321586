import { createRoutine } from 'redux-saga-routines';

const signIn = createRoutine('USER_SIGH_IN');
const sendSMS = createRoutine('USER_SEND_SMS');
const sendCodeSMS = createRoutine('USER_SEND_CODE_SMS');
const sendNewPassword = createRoutine('USER_SEND_CODE_SMS_NEW_PASS');
const setPushNotificationDispatcher = createRoutine('USER_SET_PUSH_NOTIFICATION_DISPATCHER');
const getHistoryNotification = createRoutine('GET_HISTORY_NOTIFICATION');
const getUsers = createRoutine('GET_USERS');

const setAboutUser = ({ userName, login }) => ({
  type: 'SET_USER_ABOUT_USER',
  payload: { userName, login },
});

const withExit = () => ({
  type: 'USER_EXIT',
  payload: {},
});

export default {
  signIn,
  sendSMS,
  sendCodeSMS,
  sendNewPassword,
  withExit,
  setPushNotificationDispatcher,
  getHistoryNotification,
  getUsers,
  setAboutUser,
};
