import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class FormInput extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    errorText: PropTypes.string,
    width: PropTypes.number,
    name: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    type: 'text',
    title: '',
    errorText: '',
    name: '',
    placeholder: '',
    value: '',
    error: false,
    width: 200,
    onChange: () => {},
  };

  handleChange = event => {
    const { name, onChange } = this.props;
    onChange(name, event.target.value);
  };

  render() {
    const { type, placeholder, width, title, error, value, errorText } = this.props;
    const propsInput = {
      type,
      value,
      placeholder,
      onChange: this.handleChange,
    };
    const styleWidth = width === 0 ? {} : { width };
    const isTitle = !!title.length;
    const styleError = error ? 'error-input' : 'success-input';

    return (
      <>
        <div className={`form-input ${styleError}`} style={styleWidth}>
          {isTitle ? <span>{title}</span> : null}
          <input {...propsInput} />
        </div>
        {error ? <span className="error-text-input">{errorText}</span> : null}
      </>
    );
  }
}
