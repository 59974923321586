import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const propTypes = {
  title: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

const defaultTypes = {
  title: 'введите текст',
  checked: false,
  onChange: () => {},
};

const RadioButton = ({ title, checked, onChange }) => {
  return (
    <label className="container-radio-button">
      <div className="check_container_title">{title}</div>
      <input type="checkbox" checked={checked ? 'checked' : ''} onChange={onChange} />
      <span className="checkmark-radio"></span>
    </label>
  );
};

RadioButton.propTypes = propTypes;
RadioButton.defaultTypes = defaultTypes;

export default RadioButton;
