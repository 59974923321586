import apiInstance from './tools/apiInstance';

export default {
  async findOrganziation(params) {
    const { url, token, find, isPlaces = false } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {
        find,
        lang: 'ru',
        isGos: false,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=search_organization`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setDataOrganizationRequired(params) {
    const { url, token, idObject, status } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      idObject,
      status,
    };

    try {
      const response = await api.post(
        `?id=setDataOrganizationRequired`,
        postBody,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setPushNotificationGKS(params) {
    const { url, token, data, idOrganization, message, title } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      data: JSON.stringify(data),
      idOrganization,
      message,
      title,
    };

    try {
      const response = await api.post(`?id=setPushNotificationGKS`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setOrganization(params) {
    const {
      url,
      token,
      gos,
      fullNameOrganization,
      firmsNameOrganization,
      innOrganization,
      city,
      streetOrganization,
      telephone,
      sphera,
      weekWork,
      nameSiteOrganization,
      regim,
    } = params;
    const api = apiInstance({ url, token });
    // eslint-disable-next-line no-useless-catch

    const postBody = {
      gos,
      fullNameOrganization,
      firmsNameOrganization,
      innOrganization,
      city,
      streetOrganization,
      telephone,
      sphera,
      weekWork,
      nameSiteOrganization,
      regim,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=set_organization`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  /* async setOrganizationFiles(params) {
    const { url, token, formData, vrCode, newId } = params;
    const api = apiInstance({ url, token });
    // eslint-disable-next-line no-useless-catch

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(
        `?id=add_save_org_upload&mode=safe&vr_code='+${vrCode}+'&id_='+${newId}`,
        formData,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  }, */

  async setOrganizationRequiredServiceHeatingSettingCityStreet(params) {
    const { url, token, typeRequired, idOrganization, data } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      typeRequired,
      idOrganization,
      data: JSON.stringify(data),
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(
        `?id=setOrganizationRequiredServiceHeatingSettingCityStreet`,
        postBody,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setWateDayToMonth(params) {
    const { url, token, idOrganization, dataStart = 18, dataEnd = 25 } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      idOrganization,
      dataStart: Number(dataStart),
      dataEnd: Number(dataEnd),
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setWateDayToMonth`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setOrganizationRequired(params) {
    const { url, token, typeRequired, idOrganization } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      typeRequired,
      idOrganization,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setOrganizationRequired`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getPushNotificationGKS(params) {
    const { url, token, idObject } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {
        idObject,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getPushNotificationGKS`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getDataOrganizationRequired(params) {
    const { url, token } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {},
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(
        `?id=getDataOrganizationRequired`,
        postParams,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getRequiredWaterReading(params) {
    const { url, token, filter, idFirm } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {
        filter,
        idFirm,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getRequiredWaterReading`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setRequiredWaterReadingResult(params) {
    const {
      url,
      token,
      idRequired,
      idFirm,
      resultRequired,
      cause,
      valueObject,
      firstMonth,
    } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      idRequired,
      idFirm,
      resultRequired,
      cause,
      valueObject,
      firstMonth,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(
        `?id=setRequiredWaterReadingResult`,
        postBody,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getLogWaterReading(params) {
    const { url, token, date, idOrganization } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {
        date,
        idOrganization,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getLogWaterReading`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getOrganizationList(params) {
    const {
      url,
      token,
      page,
      find,
      idOrganization,
      isNotActive,
      status,
      idCity,
      type,
      typePlaces,
      longitude,
      latitude,
      isChangeToOrgan,
    } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {
        find,
        idOrganization,
        isNotActive,
        status,
        page,
        idCity,
        type,
        typePlaces,
        longitude,
        latitude,
        isChangeToOrgan,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getOrganizationList`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setChangeStatus(params) {
    const {
      url,
      token,
      idObject,
      status,
      scope,
      isChangeOrgan = false,
    } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      idObject,
      status,
      scope,
      isChangeOrgan,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=setChangeStatus`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removeOrganization(params) {
    const { url, token, idObject } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      idObject,
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post(`?id=removeOrganization`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setADSSuccessLegalInfoOrganization(params) {
    const { url, token, idObject, type, status } = params;
    const api = apiInstance({ url, token });

    const postBody = {
      idObject,
      type,
      status,
    };
    try {
      const response = await api.post(
        `?id=setADSSuccessLegalInfoOrganization`,
        postBody,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getOrganizationComments(params) {
    const { url, token, idObject, page } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {
        idObject,
        page,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getOrganizationComments`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getInfoGKHMonitoringHeating(params) {
    const { url, token, idObject, date } = params;
    const api = apiInstance({ url, token });

    const postParams = {
      params: {
        idObject,
        date,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(
        `?id=getInfoGKHMonitoringHeating`,
        postParams,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};
