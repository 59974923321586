import apiInstance from './tools/apiInstance';

export default {
  async changeUsername(params) {
    const { url, token, username, password } = params;
    const api = apiInstance({ url, token });
    const postBody = {
      username,
      password,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post('?id=setChangeUserName', postBody);

      return { data: response.data };
    } catch (error) {
      throw error;
    }
  },

  async changePassword(params) {
    const { url, token, password, newPassword } = params;
    const api = apiInstance({ url, token });
    const postBody = {
      password,
      newPassword,
    };

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.post('?id=setChangePassword', postBody);

      return { data: response.data };
    } catch (error) {
      throw error;
    }
  },

};
