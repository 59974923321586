import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '../Modal';
import './style.css';

const propTypes = {
  value: PropTypes.string,
  isShow: PropTypes.bool,
  error: PropTypes.bool,
  title: PropTypes.string,
  titleModal: PropTypes.string,
  typeItem: PropTypes.oneOf(['default', 'button']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};
const defaultProps = {
  value: 'Выберите населенный пункт',
  title: '',
  typeItem: 'default',
  titleModal: 'Выбрать',
  isShow: false,
  error: false,
  children: () => null,
  onOpen: () => {},
  onClose: () => {},
};
const InputItem = ({ titleModal, children, value, onOpen, isShow, onClose, title, error }) => {
  const handleOpeModalClick = event => {
    onOpen();
    event.preventDefault();
  };
  const handleModalClose = () => {
    onClose();
  };

  const errorStyle = error ? 'wrapper_input_item_error' : 'wrapper_input_item_success';
  return (
    <>
      {title}
      <div className={`wrapper_input_item ${errorStyle}`}>
        <div className="wrapper_input_item_view">
          <div className="input_item_body">{value.length ? value : 'Не выбрано'}</div>
          <div className="input_item_click">
            <a href="#" onClick={handleOpeModalClick}>
              Выбрать
            </a>
          </div>
        </div>
      </div>
      <Alert disabled={isShow} title={titleModal} onClose={handleModalClose}>
        {children}
      </Alert>
    </>
  );
};

InputItem.propTypes = propTypes;
InputItem.defaultProps = defaultProps;

export default InputItem;
