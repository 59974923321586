import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  data: PropTypes.object.isRequired,
  onRemoveClick: PropTypes.func,
};

const defaultProps = {
  onRemoveClick: () => {},
};
const ListItem = ({ data, onRemoveClick }) => {
  const handleRemoveClick = (e) => {
    onRemoveClick(data.id);
    e.preventDefault();
  };

  return (
    <div className="list-item-wrapper">
      <div>
        {data.title}
        {data.subTitle ? (
          <div style={{ color: '#696969', fontSize: 12 }}>{data.subTitle}</div>
        ) : null}
      </div>

      <div>
        <a href="#" onClick={handleRemoveClick}>
          Удалить
        </a>
      </div>
    </div>
  );
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
