import apiInstance from './tools/apiInstance';

export default {
  async getHistoryBoard(params) {
    const { url, token, idInfoBlock } = params;
    const api = apiInstance({ url, token });

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getHistoryBoard`, {
        params: { idInfoBlock },
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setMainInfoBlock(params) {
    const { url, token, infoBlock, uniqID, mainSettingBackgroundImages } =
      params;
    const api = apiInstance({ url, token });
    console.log(
      'TODO: mainSettingBackgroundImages',
      mainSettingBackgroundImages,
    );
    // проверим есть ли файлы при отправки
    const formData = new FormData();
    formData.append(
      'mainSettingBackgroundImages',
      JSON.stringify(mainSettingBackgroundImages),
    );
    formData.append('uniqID', uniqID);

    if (
      mainSettingBackgroundImages.length &&
      !mainSettingBackgroundImages[0].isLoading
    ) {
      formData.append('file[]', mainSettingBackgroundImages[0].files);
    }

    if (infoBlock.screens.length) {
      for (let i = 0; i < infoBlock.screens.length; i += 1) {
        if (infoBlock.screens[i].arrayImages.length) {
          for (let o = 0; o < infoBlock.screens[i].arrayImages.length; o += 1) {
            if (!infoBlock.screens[i].arrayImages[o].isLoading) {
              formData.append(
                'file[]',
                infoBlock.screens[i].arrayImages[o].files,
              );
            }
            infoBlock.screens[i].arrayImages[o].uri = '';
            infoBlock.screens[i].arrayImages[o].url = '';
          }
        }
        // formData.append(
        //   'file[]',
        //   infoBlock.screens[i].arrayImages[o].files,
        // );
        infoBlock.screens[i].setting.background.uri = '';
        infoBlock.screens[i].setting.background.url = '';
      }
    }

    formData.append('infoBlock', JSON.stringify(infoBlock));
    try {
      const response = await api.post(`?id=setMobileInfoBlock`, formData);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async setRemoveMobileInfo(params) {
    const { url, token, removeId, type = 'default' } = params;
    const api = apiInstance({ url, token });

    // eslint-disable-next-line no-useless-catch
    try {
      const postBody = {
        removeId,
        type,
      };
      const response = await api.post(`?id=removeHistoryBoard`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async changeStatusHistoryBoard(params) {
    const { url, token, removeId } = params;
    const api = apiInstance({ url, token });

    // eslint-disable-next-line no-useless-catch
    try {
      const postBody = {
        removeId,
      };
      const response = await api.post(`?id=changeStatusHistoryBoard`, postBody);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async removeUploadFiles(params) {
    const {
      url,
      token,
      catalogID,
      isMiniImages = false,
      nameFile,
      typeFile = 'images',
    } = params;
    const api = apiInstance({ url, token });

    // eslint-disable-next-line no-useless-catch
    try {
      const postParams = {
        params: {
          catalogID,
          nameFile,
          typeFile,
          isMiniImages,
        },
      };
      const response = await api.get(`?id=removeUploadFiles`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};
