import apiInstance from './tools/apiInstance';

export default {
  async setFiles(params) {
    const { url, token, dataImages, idPost, paramsConfig } = params;
    const api = apiInstance({
      url,
      token,
    });

    const formData = new FormData();
    for (let o = 0; o < dataImages.length; o += 1) {
      console.log('TODO dataImages[o].files', dataImages[o].files);
      formData.append('image[]', dataImages[o].files);
    }

    try {
      const response = await api.post(`?id=${idPost}`, formData, paramsConfig);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },

  async getFiles(params) {
    const { url, token, page = 1 } = params;
    const api = apiInstance({
      url,
      token,
    });

    const postParams = {
      params: {
        page,
      },
    };
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getFiles`, postParams);
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};
