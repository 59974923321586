import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Header, HeaderMenu } from '../Header';

export default class CabinetMain extends PureComponent {
  static propTypes = {
    userName: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    address: PropTypes.string,
  };

  static defaultProps = {
    address: '',
    avatar: '',
  };

  render() {
    const { userName, avatar } = this.props;
    return (
      <>
        <Header>
          <HeaderMenu auth avatar={avatar} userName={userName} />
        </Header>
      </>
    );
  }
}
