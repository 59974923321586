import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import organizationActions from '../redux/actions/organization';
import organizationApi from '../../api/organization';
import errorsActions from '../redux/actions/errors';
import { userTokenSelector } from '../../selectors/user';

const { errorIsInternet } = errorsActions;

function* setOrganization(action) {
  const { success, failure, fulfill } = organizationActions.setOrganization;
  const {
    deferred,
    gos,
    fullNameOrganization,
    firmsNameOrganization,
    innOrganization,
    city,
    streetOrganization,
    telephone,
    sphera,
    weekWork,
    nameSiteOrganization,
    regim,
  } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(organizationApi.setOrganization, {
      token,
      gos,
      fullNameOrganization,
      firmsNameOrganization,
      innOrganization,
      city,
      streetOrganization,
      telephone,
      sphera,
      weekWork,
      nameSiteOrganization,
      regim,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* setOrganizationRequired(action) {
  const { success, failure, fulfill } = organizationActions.setOrganizationRequired;
  const { deferred, typeRequired, idOrganization } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(organizationApi.setOrganizationRequired, {
      token,
      typeRequired,
      idOrganization,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* getRequiredWaterReading(action) {
  const { success, failure, fulfill } = organizationActions.getRequiredWaterReading;
  const { deferred, filter, idFirm } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(organizationApi.getRequiredWaterReading, {
      token,
      filter,
      idFirm,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* setRequiredWaterReadingResult(action) {
  const { success, failure, fulfill } = organizationActions.setRequiredWaterReadingResult;
  const {
    deferred,
    idRequired,
    idFirm,
    resultRequired,
    cause,
    valueObject,
    firstMonth,
  } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  yield delay(500);
  try {
    const response = yield call(organizationApi.setRequiredWaterReadingResult, {
      token,
      idRequired,
      idFirm,
      resultRequired,
      cause,
      valueObject,
      firstMonth,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* getLogWaterReading(action) {
  const { success, failure, fulfill } = organizationActions.getLogWaterReading;
  const { deferred, date, idOrganization } = action.payload;
  yield put(errorIsInternet(false));
  const token = yield select(userTokenSelector);
  try {
    const response = yield call(organizationApi.getLogWaterReading, {
      token,
      date,
      idOrganization,
    });
    const { result } = response.data;

    const data = { data: result };

    yield put(success(data));

    if (deferred) {
      deferred.resolve(response.data);
    }
  } catch (error) {
    let status = {};
    if (error && error.response && error.response.data && error.response.data.result) {
      status = error.response.data.result;
    } else if (error.message && error.message === 'Network Error') {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }

    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeLatest(organizationActions.setOrganization.TRIGGER, setOrganization);
  yield takeLatest(organizationActions.setOrganizationRequired.TRIGGER, setOrganizationRequired);
  yield takeLatest(organizationActions.getRequiredWaterReading.TRIGGER, getRequiredWaterReading);
  yield takeLatest(
    organizationActions.setRequiredWaterReadingResult.TRIGGER,
    setRequiredWaterReadingResult,
  );
  yield takeLatest(organizationActions.getLogWaterReading.TRIGGER, getLogWaterReading);
}
