import React from 'react';
import CabinetMainContainer from '../containers/CabinetMain';

const CabinetMainScreen = props => {
  return (
    <>
      <CabinetMainContainer {...props} />
    </>
  );
};

export default CabinetMainScreen;
