import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  content: PropTypes.func,
  extraStyle: PropTypes.string.isRequired,
};

const WrapperDefault = ({ content, extraStyle }) => {
  return (
    <>
      <div className={`wrapper_default ${extraStyle}`}>{content}</div>
    </>
  );
};

WrapperDefault.propTypes = propTypes;

export default WrapperDefault;
