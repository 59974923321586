import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { getURL } from '../../navigation/staticDefaultURL';

import './headerHistoryMap.css';

const HeaderHistoryMap = () => {
  const [locationURL, setLocationURL] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const getUrlHistory = () => {
      let newHistory = String(location.pathname).split('/');
      newHistory = newHistory.filter(
        (i, index) => (i === '' && index === 0) || (i !== '' && index > 0),
      );

      setLocationURL(newHistory);
    };
    getUrlHistory();
  }, [JSON.stringify(locationURL)]);
  const countNull = 0;
  return (
    <>
      {locationURL.length > 1 ? (
        <div className="header_history_map_wrapper">
          {locationURL.map((locationItem, index) => {
            const newLocationURL = locationURL;
            const start = index + 1;
            const newArrayLocationURL = newLocationURL.filter((i, ind) => ind < start);
            const newLinkTo = newArrayLocationURL.join('/');
            if (!getURL(locationItem)) {
              return null;
            }
            return (
              <div className="header_history_map_wrapper_view" key={`${index}-${locationItem}`}>
                <div className="header_history_map_wrapper_link">
                  <Link to={`${newLinkTo}`}>{getURL(locationItem)}</Link>
                </div>
                {locationURL.length - 1 - countNull !== index ? (
                  <IoIosArrowForward size={12} color="#9C9C9C" />
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default HeaderHistoryMap;
