import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  url: PropTypes.string,
};

const defaultProps = {
  url: '',
};

const Label = ({ title, value, url }) => (
  <>
    <div className="label_wrapper">
      <div className="label_wrapper_view">
        <div className="title">{title}:</div>
        {url ? (
          <a className="label_link" href={url}>
            {value}
          </a>
        ) : (
          <div className="value">{value}</div>
        )}
      </div>
    </div>
  </>
);

Label.propTypes = propTypes;
Label.defaultProps = defaultProps;

export default Label;
