import { fromJS } from 'immutable';
import userActions from '../actions/user';
import profileActions from '../actions/profile';
import mainActions from '../actions/main';

const {
  signIn,
  sendSMS,
  sendCodeSMS,
  sendNewPassword,
  getHistoryNotification,
  getUsers,
} = userActions;
const { changeUsername, changePassword } = profileActions;
const { getInfo } = mainActions;

const DEFAULT_LOADING = {
  auth: false,
  sendSMS: false,
  organizations: false,
};

const DEFAULT_ERROR = {
  organizations: false,
};

const INITIAL_STATE = fromJS({
  user: '',
  login: '',
  token: '',
  avatar: '',
  numberPhone: '',
  codeNumber: '',
  organizations: [],
  auth: false,
  loading: DEFAULT_LOADING,
  error: DEFAULT_ERROR,
  accessRights: {},
  selectedOrganization: '',
  pushNotifications: [],
  users: {
    data: [],
    counts: 0,
    counts_mega: 0,
    countAPI: 0,
    allNumberPages: 0,
    loading: false,
    error: false,
  },
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_USER_ABOUT_USER': {
      return state.set('login', payload.login).set('user', payload.userName);
    }
    case 'ORGANIZATION_SET_SELECTED': {
      const { items } = payload;
      return state.set('selectedOrganization', items);
    }

    case changeUsername.SUCCESS: {
      const { result } = payload;
      return state.set('user', result.newUsername);
    }

    case changePassword.SUCCESS: {
      return state;
    }

    case 'USER_EXIT': {
      localStorage.setItem('mainTabIndex', 0);
      return INITIAL_STATE;
    }
    /**
     * getUsers
     */

    case getUsers.TRIGGER: {
      return state
        .setIn(['users', 'loading'], true)
        .setIn(['users', 'error'], false);
    }
    case getUsers.SUCCESS: {
      const {
        result = [],
        counts = 0,
        counts_mega = 0,
        countAPI = 0,
        AllPages = 0,
      } = payload;
      return state
        .setIn(['users', 'data'], fromJS(result))
        .setIn(['users', 'counts'], counts)
        .setIn(['users', 'counts_mega'], counts_mega)
        .setIn(['users', 'countAPI'], countAPI)
        .setIn(['users', 'allNumberPages'], AllPages);
    }
    case getUsers.FAILURE: {
      return state
        .setIn(['users', 'loading'], false)
        .setIn(['users', 'error'], true);
    }
    case getUsers.FULFILL: {
      return state.setIn(['users', 'loading'], false);
    }

    /**
     * getHistoryNotification
     */
    case getHistoryNotification.TRIGGER: {
      return state.set('pushNotifications', fromJS([]));
    }
    case getHistoryNotification.SUCCESS: {
      const { result } = payload;
      return state.set('pushNotifications', fromJS(result));
    }
    case getHistoryNotification.FAILURE: {
      return state.set('pushNotifications', fromJS([]));
    }
    /**
     * getInfo
     */
    case getInfo.TRIGGER: {
      return state
        .setIn(['loading', 'organizations'], true)
        .setIn(['error', 'organizations'], false);
    }
    case getInfo.SUCCESS: {
      const { data } = payload;
      const newData = data !== undefined ? data : [];
      return state.set('organizations', fromJS(newData));
    }
    case getInfo.FAILURE: {
      return state
        .setIn(['loading', 'organizations'], false)
        .setIn(['error', 'organizations'], true)
        .set('organizations', fromJS([]));
    }
    case getInfo.FULFILL: {
      return state.setIn(['loading', 'organizations'], false);
    }
    /**
     * signIn
     * */
    case signIn.TRIGGER: {
      return state.setIn(['loading', 'auth'], true);
    }

    case signIn.SUCCESS: {
      const { token, user, login, avatar } = payload;
      return (
        state
          .set('token', token)
          .set('user', user)
          .set('login', login)
          .set('auth', true)
          .set('codeNumber', payload.kod_number)
          .set('numberPhone', payload.number_phone)
          .set('avatar', avatar)
          // eslint-disable-next-line no-underscore-dangle
          .set('accessRights', payload.user_.length ? payload.user_[0] : {})
      );
    }

    case signIn.FULFILL: {
      return state
        .setIn(['loading', 'auth'], false)
        .set('isSilentWorking', false);
    }

    /**
     * signUp
     * */
    case sendSMS.TRIGGER: {
      return state.setIn(['loading', 'sendSMS'], true);
    }

    case sendSMS.SUCCESS: {
      return state;
    }

    case sendSMS.FULFILL: {
      return state.setIn(['loading', 'sendSMS'], false);
    }

    case sendCodeSMS.TRIGGER: {
      return state.setIn(['loading', 'sendSMS'], true);
    }

    case sendCodeSMS.SUCCESS: {
      return state;
    }

    case sendCodeSMS.FULFILL: {
      return state.setIn(['loading', 'sendSMS'], false);
    }

    case sendNewPassword.TRIGGER: {
      return state.setIn(['loading', 'sendSMS'], true);
    }

    case sendNewPassword.SUCCESS: {
      return state;
    }

    case sendNewPassword.FULFILL: {
      return state.setIn(['loading', 'sendSMS'], false);
    }

    default:
      return state;
  }
};
