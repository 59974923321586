import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const propTypes = {
  title: PropTypes.string,
  checked: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'warning']),
  onChange: PropTypes.func,
};

const defaultTypes = {
  title: 'введите текст',
  checked: false,
  type: 'default',
  onChange: () => {},
};

const CheckBox = ({ title, checked, onChange, type }) => {
  const styleType = type !== 'default' ? 'container_default' : 'container_warning';
  return (
    <label className={`container ${styleType}`}>
      <div className="check_container_title">{title}</div>
      <input type="checkbox" checked={checked ? 'checked' : ''} onChange={onChange} />
      <span className="checkmark"></span>
    </label>
  );
};

CheckBox.propTypes = propTypes;
CheckBox.defaultTypes = defaultTypes;

export default CheckBox;
