import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
// import storageSession from 'redux-persist/es/storage/session';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import ReduxSagaExposedPromise from './middlewares/ReduxSagaExposedPromise';
import rootReducer from './redux/reducers';
import rootSaga from './sagas';

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'andreybondarenko.info-app.version2',
  storage,
  whitelist: ['localSettings', 'user'],
};

const sagaMiddleware = createSagaMiddleware();

const persisterReducer = persistReducer(persistConfig, rootReducer);

const middlewares = applyMiddleware(ReduxSagaExposedPromise, sagaMiddleware);

let debugWrapper = data => data;

if (process.env.NODE_ENV === 'development') {
  debugWrapper = data => composeWithDevTools(data);
}

export const store = createStore(persisterReducer, debugWrapper(middlewares));

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
