import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Empty = ({ children }) => {
  return <div className="empty_main">{children}</div>;
};

Empty.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
};

export { Empty };
