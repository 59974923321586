const getURL = (url) => {
  if (isNaN(url) || url === '') {
    switch (url) {
      case 'organization':
        return 'Организация';
      case 'users_required':
        return 'Заявки пользователей';
      case 'poster':
        return 'Афиша';
      case 'requiredWaterReading':
        return 'Заявки на подключения';
      case 'logWaterReading':
        return 'Журнал показаний счетчика';
      case 'profile':
        return 'Мой профиль';
      case 'newOrganization':
        return 'Новая организация (бизнес профиль)';
      case 'push_notification':
        return 'Push уведомления';
      case 'organization_required':
        return 'Заявки на добавление пользователей к управлению организацией';
      case 'getFiles':
        return 'Загруженный файлы';
      case 'users':
        return 'Пользователи';
      case 'mobile_screen_info':
        return 'Истории';

      case 'new':
        return 'Новый инф. блок';
      case 'new_required':
        return 'Новая заявка';
      case 'infrastructure':
        return 'Инфраструктура из предприятий';
      case 'organization_list':
        return 'Справочник';
      case 'organization_new':
        return 'Новые заявки';
      case 'street':
        return 'Редактор улиц';
      case 'my_home':
        return 'Подключенные дома/квартиры';
      case 'places_list':
        return 'Объекты';
      case 'forum':
        return null;
      case 'new_team_and_messages':
        return 'Новые темы и сообщения';
      case 'api':
        return 'API';
      case 'disp_push_notification':
        return 'Уведомления';
      case 'cinema':
        return null;
      case 'export':
        return 'Экспорт расписаний киносеансов';
      case 'chapters':
        return 'Список глав';
      case 'feedback':
        return 'Обратная связь';
      case 'location_app':
        return 'Доступность приложения';
      case 'rayon':
        return 'Районы и Городские округа';
      case 'city':
        return 'Города и населенные пункты';
      case 'films':
        return 'Фильмы';
      case 'complaint':
        return 'Жалоба от пользователей';
      case 'session':
        return 'Сеансы фильмов';
      case 'interviewer_video':
        return 'Интервьюеры - видео';
      case 'catalog':
        return 'Форум - каталог';

      case 'category_objects':
        return 'Категории объектов';
      case 'payments':
        return 'Платежи';
      case 'posts':
        return 'Модерация постов';
      case 'modern_posts':
        return 'Модерация мероприятий';
      case 'm_news':
        return 'Новости и происшествия';

      case 'news':
        return 'Новости и происшествия';
      case 'chats':
        return 'Чаты';
      case 'category':
        return 'Категории';
      case 'posters':
        return 'Мероприятия и события';
      case 'gkh':
        return null;
      case 'ads_category':
        return 'Категория';
      case 'location_setting':
        return 'ЖКУ - Настройки подкл. объектов';
      case 'monitoring_heating':
        return 'ЖКУ - Мониторинг тепла';
      case 'gks_push_notification':
        return 'ЖКУ - Уведомления';
      case 'terra_blogger':
        return 'Terra Blogger';
      default:
        return 'Главная';
    }
  }
  return '';
};

// eslint-disable-next-line import/prefer-default-export
export { getURL };
