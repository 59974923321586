import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class Loader extends PureComponent {
  static propTypes = {
    size: PropTypes.number,
    type: PropTypes.oneOf(['white', 'black']),
  };

  static defaultProps = {
    type: 'white',
    size: 60,
  };

  render() {
    const { size, type } = this.props;
    const style = { width: size, height: size };
    const typeStyle = type === 'white' ? 'sk-circle-white' : 'sk-circle-silver';

    return (
      <div className="wrapper-loader" style={style}>
        <div className="sk-fading-circle" style={style}>
          <div className={`sk-circle1 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle2 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle3 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle4 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle5 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle6 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle7 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle8 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle9 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle10 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle11 sk-circle ${typeStyle}`}></div>
          <div className={`sk-circle12 sk-circle ${typeStyle}`}></div>
        </div>
      </div>
    );
  }
}
