import { createSelector } from 'reselect';

const userStateSelector = (state) => state.user.toJS();

const userTokenSelector = createSelector(
  userStateSelector,
  ({ token }) => token,
);

const userNameSelector = createSelector(userStateSelector, ({ user }) => user);

const userAllUsersItemsSelector = createSelector(
  userStateSelector,
  ({ users }) => users,
);

const userPushNotificationsSelector = createSelector(
  userStateSelector,
  ({ pushNotifications }) => pushNotifications,
);

const userAccessRightsSelector = createSelector(
  userStateSelector,
  ({ accessRights }) => accessRights,
);

const loginSelector = createSelector(userStateSelector, ({ login }) => login);

const userAuthSelector = createSelector(userStateSelector, ({ auth }) => auth);

const userLoadingSelector = createSelector(
  userStateSelector,
  ({ loading }) => loading,
);

const userAvatarSelector = createSelector(
  userStateSelector,
  ({ avatar }) => avatar,
);

const userOrganizationsSelector = createSelector(
  userStateSelector,
  ({ organizations }) => organizations,
);

export {
  userNameSelector,
  loginSelector,
  userAuthSelector,
  userLoadingSelector,
  userAvatarSelector,
  userTokenSelector,
  userOrganizationsSelector,
  userAccessRightsSelector,
  userPushNotificationsSelector,
  userAllUsersItemsSelector,
};
