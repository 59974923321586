import { createRoutine } from 'redux-saga-routines';

const getMobileInfo = createRoutine('ADMIN_GET_INFO_BLOCK');
const setMainInfoBlock = createRoutine('ADMIN_SET_INFO_BLOCK');
const setRemoveMobileInfo = createRoutine('ADMIN_SET_REMOVE_INFO_BLOCK');

export default {
  getMobileInfo,
  setMainInfoBlock,
  setRemoveMobileInfo,
};
