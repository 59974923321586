import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Input';
import Loader from '../Loader';
import { InputItem } from '../InputItem';
import apiInstance from '../../../api/tools/apiInstance';
import './style.css';

const propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.oneOf(['city', 'rayon']),
  error: PropTypes.bool,
  value: PropTypes.string,
  token: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultProps = {
  title: '',
  token: '',
  type: 'city',
  error: false,
  value: '',
  onChange: () => {},
};

const InputItemCity = ({ token, error, onChange, value, title, type }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [findCity, setFindCity] = useState('');

  const getFindCity = async () => {
    const api = apiInstance({ token });
    setLoading(true);
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await api.get(`?id=getCity`, {
        params: {
          // mode: type === 'city' ? 'city' : 'rayon',
          findCity,
          isSection: true,
          // find: findCity,
          // lang: 'ru',
        },
      });
      const { result } = response.data;
      if (result) {
        setData(result);
      }
      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const handleFindChange = async (text) => {
    setFindCity(text);
    if (findCity.length > 3) {
      try {
        await getFindCity({ findCity, deferred: true });
      } catch (e) {}
    }
  };

  const handleCitySelect = (id, city, type) => {
    setIsShow(false);
    onChange(id, city, type);
  };

  const handleItemCityOpen = () => {
    setIsShow(true);
  };
  const handleItemCityClose = () => {
    setIsShow(false);
  };
  return (
    <InputItem
      title={title}
      error={error}
      value={value}
      onOpen={handleItemCityOpen}
      onClose={handleItemCityClose}
      isShow={isShow}>
      <Input
        typeText="search"
        title={
          type === 'city'
            ? 'Найти населенный пункт'
            : 'Найти насел. пункт или округ'
        }
        placeholder={
          type === 'city'
            ? 'Золотая Долина'
            : 'Партизанский муниципальный округ'
        }
        value={findCity}
        onChange={handleFindChange}
      />
      <div className="input_item_city">
        {loading ? <Loader type="black" size={25} /> : null}
        {data.map((item) => {
          const onSelectRayon = (event) => {
            if (type === 'rayon') {
              handleCitySelect(item.idRayon, `${item.title}`, 'rayon');
            }
            event.preventDefault();
          };

          return (
            <div key={item.id}>
              <a className="input_item_city_a" href="#" onClick={onSelectRayon}>
                {String(item.title).toUpperCase()}
              </a>
              <div>
                {item.data.map((ct) => {
                  const onSelect = (event) => {
                    handleCitySelect(
                      ct.id,
                      `${ct.region}, ${ct.title}`,
                      'city',
                    );
                    event.preventDefault();
                  };
                  return (
                    <div key={ct.id} style={{ paddingLeft: 10 }}>
                      <a
                        className="input_item_city_a"
                        href="#"
                        onClick={onSelect}>
                        {ct.region}, {ct.title}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </InputItem>
  );
};

InputItemCity.propTypes = propTypes;
InputItemCity.defaultProps = defaultProps;

export default InputItemCity;
