import { put, call, takeLatest, select } from "redux-saga/effects";
import userActions from "../redux/actions/user";
import errorsActions from "../redux/actions/errors";
import userApi from "../../api/user";
import { userTokenSelector } from "../../selectors/user";

const { errorIsInternet } = errorsActions;

function* signIn(action) {
  const { success, failure, fulfill } = userActions.signIn;
  const { user, password, deferred } = action.payload;
  yield put(errorIsInternet(false));

  try {
    const response = yield call(userApi.signIn, { user, password });
    const { result } = response.data;

    yield put(success(result));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    let status = {};
    if (error.message && error.message === "Network Error") {
      yield put(errorIsInternet(true));
      status = { isErrorInternet: true };
    } else if (deferred) {
      status = { isErrorInternet: false };
    }
    if (deferred) {
      deferred.reject(status);
    }
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* sendSMS(action) {
  const { success, failure, fulfill } = userActions.sendSMS;
  const { phone, deferred } = action.payload;
  try {
    const response = yield call(userApi.sendSMS, { phone });
    const { result } = response.data;

    yield put(success(result));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    if (deferred) {
      deferred.reject(error);
    }
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* sendCodeSMS(action) {
  const { success, failure, fulfill } = userActions.sendCodeSMS;
  const { phone, codeSMS, deferred } = action.payload;
  try {
    const response = yield call(userApi.sendCodeSMS, { phone, codeSMS });
    const { result } = response.data;

    yield put(success(result));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    if (deferred) {
      deferred.reject(error);
    }
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* sendNewPassword(action) {
  const { success, failure, fulfill } = userActions.sendNewPassword;
  const { phone, codeSMS, deferred, newPass } = action.payload;
  try {
    const response = yield call(userApi.sendNewPassword, {
      phone,
      codeSMS,
      newPass,
    });
    const { result } = response.data;

    yield put(success(result));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    if (deferred) {
      deferred.reject(error);
    }
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* setPushNotificationDispatcher(action) {
  const {
    success,
    failure,
    fulfill,
  } = userActions.setPushNotificationDispatcher;
  const {
    deferred,
    typeSend,
    arrayCity,
    arrayStreet,
    message,
    titlePush,
    data_,
  } = action.payload;

  const token = yield select(userTokenSelector);

  try {
    const response = yield call(userApi.setPushNotificationDispatcher, {
      token,
      titlePush,
      typeSend,
      arrayCity,
      arrayStreet,
      message,
      data_,
    });
    const { result } = response.data;

    yield put(success(result));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    if (deferred) {
      deferred.reject(error);
    }
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* getHistoryNotification(action) {
  const { success, failure, fulfill } = userActions.getHistoryNotification;
  const { deferred } = action.payload;

  const token = yield select(userTokenSelector);

  try {
    const response = yield call(userApi.getHistoryNotification, {
      token,
    });

    yield put(success(response.data));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    if (deferred) {
      deferred.reject(error);
    }
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

function* getUsers(action) {
  const { success, failure, fulfill } = userActions.getUsers;
  const { deferred, page, id_user = 0, find = "" } = action.payload;

  const token = yield select(userTokenSelector);

  try {
    const response = yield call(userApi.getUsers, {
      token,
      page,
      id_user,
      find,
    });

    yield put(success(response.data));

    if (deferred) {
      deferred.resolve();
    }
  } catch (error) {
    if (deferred) {
      deferred.reject(error);
    }
    yield put(failure());
  } finally {
    yield put(fulfill());
  }
}

export default function* watcherSaga() {
  yield takeLatest(userActions.signIn.TRIGGER, signIn);
  yield takeLatest(userActions.sendSMS.TRIGGER, sendSMS);
  yield takeLatest(userActions.sendCodeSMS.TRIGGER, sendCodeSMS);
  yield takeLatest(userActions.sendNewPassword.TRIGGER, sendNewPassword);
  yield takeLatest(
    userActions.setPushNotificationDispatcher.TRIGGER,
    setPushNotificationDispatcher
  );
  yield takeLatest(
    userActions.getHistoryNotification.TRIGGER,
    getHistoryNotification
  );
  yield takeLatest(userActions.getUsers.TRIGGER, getUsers);
}
