import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircle } from 'react-icons/io';
import { Input } from '../../Input';
import Loader from '../Loader';
import { InputItem } from '../InputItem';
import apiInstance from '../../../api/tools/apiInstance';
import './style.css';

const propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  error: PropTypes.bool,
  defaultData: PropTypes.array,
  value: PropTypes.string,
  token: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultProps = {
  title: '',
  token: '',
  error: false,
  value: '',
  defaultData: [],
  onChange: () => {},
};

const useChangeDataCheck = (data, index, subIndex) => {
  const newData = data;
  newData[index].podItems[subIndex].check = !newData[index].podItems[subIndex].check;
  return newData;
};

const useRemoveDataPodItems = (data, index) => {
  const newData = data;
  newData[index].podItems = [];
  return newData;
};

const InputItemSphera = ({ token, onChange, value, title, defaultData, error }) => {
  const [noLoading, setNoLoading] = useState(true);

  const [loading, setLoading] = useState(false);
  const [subIndex, setSubIndex] = useState(false);
  const [selectedData, setSelectedData] = useState(defaultData);
  const [isUpdate, setIsUpdate] = useState(true);
  const [idItem, setIdItem] = useState(0);
  const [indexItem, setIndexItem] = useState(-1);
  const [data, setData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [findSphera, setFindSphera] = useState('');

  useEffect(() => {});

  useEffect(() => {
    let ignore = false;
    async function getAllSphera() {
      if (
        (!data.length && idItem === 0 && indexItem === -1) ||
        (data.length && idItem !== 0 && indexItem !== -1)
      ) {
        const api = apiInstance({ token });
        if (idItem === 0) {
          setLoading(true);
        }
        // eslint-disable-next-line no-useless-catch
        try {
          const response = await api.get(`?id=load_spravochniki`, {
            params: {
              mode: 'safe',
              q: idItem,
              lang: 'ru',
            },
          });
          if (idItem === 0) {
            const { result } = await response.data;
            if (result) {
              if (!ignore) setData(result);
            }
          } else {
            const newItemsSphera = data.map((item, itemIndex) => {
              if (indexItem !== itemIndex) return item;
              return { ...item, podItems: response.data.pod_spr, check: false };
            });
            if (!ignore) setData(newItemsSphera);
          }
          setSubIndex(-1);
          setLoading(false);
        } catch (errors) {
          throw errors;
        }
      }
    }

    getAllSphera();
    return () => {
      ignore = true;
    };
  }, [idItem, indexItem]);

  useEffect(() => {
    let ignore = false;

    async function searchSphera() {
      const api = apiInstance({ token });
      if (idItem === 0) {
        setLoading(true);
      }
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await api.get(`?id=search_org_sphera`, {
          params: {
            mode: 'safe',
            search: findSphera,
            lang: 'ru',
          },
        });
        if (idItem === 0) {
          const { result } = await response.data;
          if (result) {
            if (!ignore) setData(result);
          }
        } else {
          const newItemsSphera = data.map((item, itemIndex) => {
            if (indexItem !== itemIndex) return item;
            return { ...item, podItems: response.data.pod_spr, check: false };
          });
          if (!ignore) setData(newItemsSphera);
        }
        setSubIndex(-1);
        setLoading(false);
      } catch (errors) {
        throw errors;
      }
    }
    if (findSphera.length > 2) {
      setData([]);
      setIdItem(0);
      setIndexItem(3);
      searchSphera();
      setNoLoading(false);
    } else if (!noLoading) {
      setData([]);
      setIdItem(0);
      setIndexItem(-1);
      setIsUpdate(!isUpdate);
      setNoLoading(true);
    }
    return () => {
      ignore = true;
    };
  }, [findSphera]);

  const handleSpheraSelect = (id, index) => {
    if (id === idItem) {
      const newData = useRemoveDataPodItems(data, index);
      setSubIndex(-1);
      setIdItem(0);
      setIndexItem(-1);
      setData(newData);
    } else {
      // Предыдущие надо удалить
      if (indexItem > -1) {
        const newData = useRemoveDataPodItems(data, indexItem);
        setData(newData);
      }
      setSubIndex(index);
      setIdItem(id);
      setIndexItem(index);
    }
  };

  const handleSpheraItemsSelect = (id, valueItem, idd, index, subIndexItem) => {
    const newData = useChangeDataCheck(data, index, subIndexItem);
    const selectedDataIndex = selectedData.findIndex(i => i.id === id);
    if (selectedDataIndex > -1) {
      selectedData.splice(selectedDataIndex, 1);
    } else {
      selectedData.push({
        id,
        title: valueItem,
      });
    }
    setSelectedData(selectedData);
    setIsUpdate(!isUpdate);
    setData(newData);
    onChange(selectedData);
  };

  const handleItemCityOpen = () => {
    setIsShow(true);
  };
  const handleItemCityClose = () => {
    setIsShow(false);
  };

  const handleRemoveItem = item => {
    const selectedDataIndex = selectedData.findIndex(i => i.id === item.id);
    if (selectedDataIndex > -1) {
      selectedData.splice(selectedDataIndex, 1);
    }
    setSelectedData(selectedData);
    setIsUpdate(!isUpdate);
  };

  const handleFindSpheraChange = valueFindSphera => {
    setFindSphera(valueFindSphera);
    setIsUpdate(!isUpdate);
  };
  return (
    <>
      <InputItem
        title={title}
        value=""
        error={error}
        onOpen={handleItemCityOpen}
        onClose={handleItemCityClose}
        isShow={isShow}
      >
        <Input
          typeText="search"
          title="Найти сферу деятельности"
          placeholder="администрация"
          value={findSphera}
          onChange={handleFindSpheraChange}
        />
        <div className="input_item_city">
          {loading ? <Loader type="black" size={25} /> : null}
          {data.map((item, index) => {
            const onSelect = event => {
              if (noLoading) {
                handleSpheraSelect(item.id, index);
              }
              event.preventDefault();
            };

            // if (index !== 0) {
            return (
              <div key={`${item.id}-${index}`}>
                <a className="input_item_city_a" href="#" onClick={onSelect}>
                  {item.title}
                </a>
                {subIndex === index ? (
                  <div className="input_item_city_a_sphera_loader">
                    <Loader type="black" size={18} />
                  </div>
                ) : null}
                {item.podItems && item.podItems.length ? (
                  <div className="input_item_city_a_sphera">
                    {item.podItems.map((subItem, subIndexI) => {
                      const selectedDataIndex = selectedData.findIndex(i => i.id === subItem.id);
                      const isSelectedData = selectedDataIndex > -1;
                      const onSelectItems = event => {
                        handleSpheraItemsSelect(
                          subItem.id,
                          `${subItem.title}`,
                          item.id,
                          index,
                          subIndexI,
                        );
                        event.preventDefault();
                      };
                      return (
                        <div key={`${subIndexI}-${item.id}`} className="input_item_city_a_view">
                          {isSelectedData ? (
                            <div className="input_item_city_a_icon">
                              <IoIosCheckmarkCircleOutline color="green" size={20} />
                            </div>
                          ) : (
                            <div className="input_item_city_a_icon" />
                          )}
                          <a className="input_item_city_a" href="#" onClick={onSelectItems}>
                            {subItem.title}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
            // }
            return null;
          })}
        </div>
      </InputItem>
      {selectedData.length ? (
        <div className="input_item_city_preview">
          <div className="input_item_city_preview_title">
            <span>Всего: {selectedData.length} шт.</span>
          </div>
          <div className="input_item_city_a_view_selected">
            {selectedData.map(item => {
              const onRemoveItems = event => {
                handleRemoveItem(item);
                event.preventDefault();
              };
              return (
                <div key={item.id} className="input_item_city_a_view">
                  <div className="input_item_city_a_icon">
                    <a href="#" onClick={onRemoveItems}>
                      <IoIosCloseCircle color="red" size={25} />
                    </a>
                  </div>
                  <div className="input_item_city_a_text">{item.title}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

InputItemSphera.propTypes = propTypes;
InputItemSphera.defaultProps = defaultProps;

export default InputItemSphera;
