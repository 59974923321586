import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../../constants';

export default (params = {}) => {
  const { url, token } = params;
  const baseURL = url || config.apiUrl;
  const timezone = moment.tz.guess();

  const instanceConfig = {
    baseURL,
    headers: {
      // 'Content-Type': 'application/vnd.api+json',
      // 'Access-Control-Allow-Origin': '*',
      // Accept: '*/*',
      // Accept: 'application/json',
      timezone,
      // mycity: '',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      // 'Access-Control-Allow-Origin': '*',
    },
    // timeout: 20000,
  };

  if (token) {
    instanceConfig.headers.Authorization = `Bearer ${token}`;
  }

  return axios.create(instanceConfig);
};
