import actionTypes from '../actionTypes';

const { ERRORS_IS_CONNECTION_INTERNET } = actionTypes;

const errorIsInternet = error => ({
  type: ERRORS_IS_CONNECTION_INTERNET,
  payload: {
    error,
  },
});

export default {
  errorIsInternet,
};
