import React, { Component, Suspense, lazy, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Preloader from '../components/FormControl/Preloader';
// eslint-disable-next-line no-unused-vars
import PrivateRoute from './PrivateRoute';
import { store } from '../store';
import CabMainScreen from '../screens/CabMainScreen';
import { InfoAlert } from '../components/FormControl';

const configLocal = require('../config-local.json');

const { basename } = configLocal;

const BloggerList = lazy(() => import('../screens/TerraBlogger/BloggerList'));
const BloggerCategory = lazy(() =>
  import('../screens/TerraBlogger/BloggerCategory'),
);

const ReceptionService = lazy(() => import('../services/ReceptionService'));

const UserPreviewScreen = lazy(() => import('../screens/UserPreviewScreen'));

const APIDispatchPushNotification = lazy(() =>
  import('../screens/API/APIDispatchPushNotification'),
);
const Rayon = lazy(() => import('../screens/CityService/Rayon'));
const City = lazy(() => import('../screens/CityService/City'));
const FilesScreen = lazy(() => import('../screens/FilesScreen'));
const ChaptersList = lazy(() => import('../screens/Chapters/ChaptersList'));
const ChaptersView = lazy(() => import('../screens/Chapters/ChapterView'));
const Payments = lazy(() => import('../screens/Payments'));
const ModerationPost = lazy(() => import('../screens/ModerationPost'));
const ModerationPoster = lazy(() => import('../screens/ModerationPoster'));
const OrganizationRequiredScene = lazy(() =>
  import('../scene/OrganizationRequiredScene'),
);

const ForumCatalog = lazy(() => import('../screens/Forum/ForumCatalog'));

const Posters = lazy(() => import('../screens/Posters/Poster'));
const News = lazy(() => import('../screens/News/News'));

const AdsCategory = lazy(() => import('../screens/Ads/AdsCategory'));

const InterviewerVideo = lazy(() =>
  import('../screens/Interviewer/InterviewerVideo/InterviewerVideo'),
);

const ChatList = lazy(() => import('../screens/Chats/ChatList'));
const ChatMessages = lazy(() => import('../screens/Chats/ChatMessages'));

const StartMainScreen = lazy(() => import('../screens/StartMainScreen'));

const Сomplaint = lazy(() => import('../screens/admin/Сomplaint'));
const CategoryObjects = lazy(() => import('../screens/admin/CategoryObjects'));

const NewsScreen = lazy(() => import('../screens/NewsScreen'));
const PosterScreen = lazy(() => import('../screens/PosterScreen'));
const NewRequiredScreen = lazy(() => import('../screens/NewRequiredScreen'));

const LocationApp = lazy(() => import('../screens/LocationApp/LocationApp'));

const MonitoringHeating = lazy(() =>
  import('../screens/gkh/MonitoringHeating'),
);
const ServiceSetting = lazy(() => import('../screens/gkh/ServiceSetting'));
const PushNotificationGKS = lazy(() =>
  import('../screens/gkh/PushNotificationGKS'),
);

const CinemaExport = lazy(() => import('../screens/Cinema/CinemaExport'));
const CinemaCatalog = lazy(() => import('../screens/Cinema/CinemaCatalog'));
const SessionScreen = lazy(() => import('../screens/Cinema/SessionScreen'));

const Feedback = lazy(() => import('../screens/Feedback'));

const StreetScreen = lazy(() => import('../screens/StreetScreen'));

const signUpScreen = lazy(() => import('../screens/SignUp/SignUpScreen'));
const SignIn = lazy(() => import('../screens/SignIn'));
const ExitScreen = lazy(() => import('../screens/Exit'));

const OrganizationScreen = lazy(() => import('../screens/OrganizationScreen'));
const OrganizationListScreen = lazy(() =>
  import('../screens/OrganizationListScreen'),
);
const OrganizationCardScreen = lazy(() =>
  import('../screens/OrganizationCardScreen'),
);
const RequiredWaterReadingScreen = lazy(() =>
  import('../screens/RequiredWaterReadingScreen'),
);
const UserRequired = lazy(() => import('../screens/UserRequired/'));

const LogWaterReadingScreen = lazy(() =>
  import('../screens/LogWaterReadingScreen'),
);

const MainScreen = lazy(() => import('../screens/MainScreen/'));
const NewOrganizationScreen = lazy(() =>
  import('../screens/NewOrganizationScreen'),
);
const ProfileScreen = lazy(() => import('../screens/ProfileScreen'));

const PushNotificationScreen = lazy(() =>
  import('../screens/PushNotificationScreen'),
);

const UsersScreen = lazy(() => import('../screens/UsersScreen'));
const MobileInfoScreen = lazy(() => import('../screens/MobileInfoScreen'));
const MobileInfoNew = lazy(() =>
  import('../scene/MobileInfoNew/MobileInfoNew'),
);

const InfrastructureScreen = lazy(() =>
  import('../screens/InfrastructureScreen'),
);

// const DownloadMobileScene = lazy(() =>
//   import('../screens/DownloadMobileScene/DownloadMobileScene'),
// );
const MyHome = lazy(() => import('../screens/MyHome/MyHome'));

const ForumNewTeamMessages = lazy(() =>
  import('../screens/Forum/ForumNewTeamMessages/ForumNewTeamMessages'),
);

const ForumTeamMessages = lazy(() =>
  import('../screens/Forum/ForumTeamMessages/ForumTeamMessages'),
);

const PlacesList = lazy(() => import('../screens/Places/PlacesList'));

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.store = store;
  }

  state = {
    auth: false,
  };

  render() {
    const { auth, accessRights, token } = this.store.getState().user.toJS();
    return (
      <Router basename={basename}>
        <div>
          {!auth ? (
            <PublicRouters />
          ) : (
            <PrivateRouters
              token={token}
              auth={auth}
              accessRights={accessRights}
            />
          )}
        </div>
      </Router>
    );
  }
}

const PublicRouters = () => (
  <Suspense fallback={<Preloader />}>
    <Route path="/" exact component={StartMainScreen} />
    <Route path="/signUp" component={signUpScreen} />
    <Route path="/signIn" component={SignIn} />
    <Route
      exact
      path="/reception/:type/:unic_id"
      component={(props) => <ReceptionService {...props} />}
    />
    {/* <Route path="/i" component={DownloadMobileScene} />
    <Route path="/i/*" component={DownloadMobileScene} /> */}
  </Suspense>
);

const Layout = ({ accessRights, token }) => {
  const infoAlert = useRef();
  return (
    <Suspense fallback={<Preloader />}>
      <div>
        {CabMainScreen()}
        <InfoAlert ref={infoAlert} />
        <div>
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => (
                <MainScreen {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/reception/:type/:unic_id"
              component={(props) => <ReceptionService {...props} />}
            />

            <Route
              exact
              path="/forum/catalog"
              component={(props) => (
                <ForumCatalog {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/forum/catalog/:idObject/"
              component={(props) => (
                <ForumCatalog {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/api/disp_push_notification"
              component={(props) => (
                <APIDispatchPushNotification {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/ads_category/"
              component={(props) => (
                <AdsCategory {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/ads_category/:idObject/"
              component={(props) => (
                <AdsCategory {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/getFiles/"
              component={(props) => (
                <FilesScreen {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/organization_required/"
              component={(props) => (
                <OrganizationRequiredScene {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/chapters/"
              component={(props) => (
                <ChaptersList {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/chapters/:idChapter"
              component={(props) => (
                <ChaptersView {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/terra_blogger/"
              component={(props) => (
                <BloggerList {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/terra_blogger/category/"
              component={(props) => (
                <BloggerCategory {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/users_required/"
              component={(props) => (
                <UserRequired {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/organization/chats/"
              component={(props) => (
                <ChatList {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/organization/chats/:idChat/:idUser/:idType/"
              component={(props) => (
                <ChatMessages {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/feedback/chats/:idChat/:idUser/:idType/"
              component={(props) => (
                <ChatMessages {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/Feedback/"
              component={(props) => (
                <Feedback {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/Posters/"
              component={(props) => (
                <Posters {...props} infoAlert={infoAlert} isAdminPanel />
              )}
            />

            <Route
              exact
              path="/forum/new_team_and_messages"
              component={(props) => (
                <ForumNewTeamMessages {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/forum/new_team_and_messages/:idObject"
              component={(props) => (
                <ForumTeamMessages {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/location_app/"
              component={(props) => (
                <LocationApp {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/cinema/export/"
              component={(props) => (
                <CinemaExport {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/cinema/films/"
              component={(props) => (
                <CinemaCatalog {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/cinema/films/session/:idFilm"
              component={(props) => (
                <SessionScreen {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/category_objects"
              component={(props) => (
                <CategoryObjects {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/payments"
              component={(props) => (
                <Payments {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/posts"
              component={(props) => (
                <ModerationPost {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/modern_posts"
              component={(props) => (
                <ModerationPoster {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/newOrganization"
              component={NewOrganizationScreen}
            />
            <Route exact path="/profile" component={ProfileScreen} />
            <Route exact path="/organization" component={OrganizationScreen} />
            <Route
              exact
              path="/places_list"
              component={() => <PlacesList infoAlert={infoAlert} />}
            />
            <Route
              exact
              path="/organization/gkh/monitoring_heating"
              component={(props) => (
                <MonitoringHeating {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/organization/gkh/gks_push_notification"
              component={(props) => (
                <PushNotificationGKS {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/organization_list/"
              component={() => (
                <OrganizationListScreen token={token} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/organization/complaint"
              component={(props) => (
                <Сomplaint {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/rayon"
              component={(props) => <Rayon {...props} infoAlert={infoAlert} />}
            />
            <Route
              exact
              path="/city"
              component={(props) => <City {...props} infoAlert={infoAlert} />}
            />

            <Route
              exact
              path="/organization_list/:idObject"
              component={() => (
                <OrganizationCardScreen token={token} infoAlert={infoAlert} />
              )}
            />
            {accessRights && accessRights.access_spravochniki_city && (
              <Route exact path="/street/" component={StreetScreen} />
            )}
            {accessRights && accessRights.access_dispatcher && (
              <Route
                exact
                path="/push_notification/"
                component={(props) => (
                  <PushNotificationScreen {...props} infoAlert={infoAlert} />
                )}
              />
            )}
            <Route exact path="/users/" component={UsersScreen} />
            <Route
              exact
              path="/users/:idObject"
              component={(props) => (
                <UserPreviewScreen {...props} infoAlert={infoAlert} />
              )}
            />

            <Route
              exact
              path="/mobile_screen_info/"
              component={() => <MobileInfoScreen infoAlert={infoAlert} />}
            />
            <Route
              exact
              path="/mobile_screen_info/new/"
              component={() => <MobileInfoNew infoAlert={infoAlert} />}
            />
            <Route
              exact
              path="/mobile_screen_info/new/:idObject"
              component={(props) => (
                <MobileInfoNew {...props} infoAlert={infoAlert} />
              )}
            />
            <Route
              exact
              path="/my_home/"
              component={() => {
                return <MyHome infoAlert={infoAlert} />;
              }}
            />
            <Route
              exact
              path="/organization/gkh/requiredWaterReading/"
              component={RequiredWaterReadingScreen}
            />
            <Route
              exact
              path="/organization/gkh/logWaterReading/"
              component={LogWaterReadingScreen}
            />
            <Route
              exact
              path="/organization/poster/"
              component={() => <Posters infoAlert={infoAlert} />}
            />
            <Route
              exact
              path="/organization/news/"
              component={() => (
                <News infoAlert={infoAlert} isAdminPanel={false} />
              )}
            />
            <Route
              exact
              path="/interviewer_video/"
              component={() => <InterviewerVideo infoAlert={infoAlert} />}
            />
            <Route
              exact
              path="/organization/gkh/location_setting"
              component={() => <ServiceSetting infoAlert={infoAlert} />}
            />

            <Route
              exact
              path="/m_news/"
              component={() => <News infoAlert={infoAlert} isAdminPanel />}
            />

            <Route
              exact
              path="/organization/new_required/"
              component={() => <NewRequiredScreen infoAlert={infoAlert} />}
            />
            <Route
              exact
              path="/infrastructure/"
              component={() => (
                <InfrastructureScreen token={token} infoAlert={infoAlert} />
              )}
            />
            <Route exact path="/exit" component={ExitScreen} />
            {/* <Route path="/i" component={DownloadMobileScene} />
            <Route path="/i/*" component={DownloadMobileScene} /> */}
          </Switch>
        </div>
      </div>
    </Suspense>
  );
};

const PrivateRouters = ({ accessRights, token }) => {
  const component = () => {
    return <Layout accessRights={accessRights} token={token} />;
  };
  return <Route exact component={component} />;
};

PrivateRouters.propTypes = {
  accessRights: PropTypes.object,
};

Layout.propTypes = {
  accessRights: PropTypes.object,
};

export default Navigation;
