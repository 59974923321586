import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { IoIosCloseCircle, IoMdClose, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ReactPlayer from 'react-player';
import YouTube from 'react-youtube';

import './style.css';

const propTypes = {
  images: PropTypes.array.isRequired,
  isOne: PropTypes.bool,
  maxImagesView: PropTypes.number,
  isBTNRemove: PropTypes.bool,
  objectNameParamsMini: PropTypes.string,
  objectNameParams: PropTypes.string,
  styleContentCSS: PropTypes.string,
  styleImageCSS: PropTypes.string,
  onRemove: PropTypes.func,
};

const defaultProps = {
  isBTNRemove: false,
  isOne: false,
  maxImagesView: 0,
  objectNameParamsMini: 'url_mini',
  objectNameParams: 'url',
  styleContentCSS: '',
  styleImageCSS: '',
  onRemove: () => {},
};

const ImagesPreview = ({
  images,
  isOne,
  isBTNRemove,
  maxImagesView,
  objectNameParamsMini,
  objectNameParams,
  styleContentCSS,
  styleImageCSS,
  onRemove,
}) => {
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(images.length);
  const [isPreview, setIsPreview] = useState(false);

  const handlePreviewClick = index => {
    setPage(index + 1);
    setIsPreview(true);
  };

  const handlePreviewCardClose = () => {
    setIsPreview(false);
  };

  const handlePreviewCardPrev = () => {
    setPage(page - 1);
  };
  const handlePreviewCardNext = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    setMaxPages(images.length);
  }, [images]);

  return (
    <>
      <div className={`images-preview-cards ${styleContentCSS}`}>
        {images.map((image, index) => {
          if (maxImagesView > 0) {
            if (index > 0) return null;
          }
          const onPreviewClick = () => {
            handlePreviewClick(index);
          };
          const handleImageRemove = e => {
            e.preventDefault();
            onRemove(index);
          };
          if (!isOne || (isOne && index === 0 && !image.type)) {
            return (
              <div className={`images-preview-card ${styleImageCSS}`} key={`imagesPreview${index}`}>
                {isBTNRemove ? (
                  <div className="image-remove">
                    <a href="#" onClick={handleImageRemove}>
                      <IoIosCloseCircle color="red" size={25} />
                    </a>
                  </div>
                ) : null}

                <img
                  onClick={onPreviewClick}
                  className="images-preview-card-image"
                  src={image[objectNameParamsMini]}
                />
              </div>
            );
          }
        })}
      </div>
      {isPreview ? (
        <div className="images-preview-cards-full-screen">
          <div className="close" onClick={handlePreviewCardClose}>
            <IoMdClose color="#FFF" size={45} />
          </div>

          {page > 1 ? (
            <div className="left-button" onClick={handlePreviewCardPrev}>
              <IoIosArrowBack color="#FFF" size={55} />
            </div>
          ) : null}
          <div className="preview-images">
            {images[page - 1] &&
            images[page - 1].type &&
            (images[page - 1].type === 'mp4' || images[page - 1].type === 'linkMP4') ? (
              <ReactPlayer light url={images[page - 1][objectNameParams]} controls />
            ) : null}
            {images[page - 1] && !images[page - 1].type ? (
              <img className="preview-images-image" src={images[page - 1][objectNameParams]} />
            ) : null}
            {images[page - 1] && images[page - 1].type && images[page - 1].type === 'youTube' ? (
              <YouTube videoId={images[page - 1][objectNameParams]} />
            ) : null}
          </div>
          {page < maxPages ? (
            <div className="right-button" onClick={handlePreviewCardNext}>
              <IoIosArrowForward color="#FFF" size={55} />
            </div>
          ) : null}
          <div className="pages">
            Страница: {page} из {maxPages}
          </div>
        </div>
      ) : null}
    </>
  );
};

ImagesPreview.propTypes = propTypes;
ImagesPreview.defaultProps = defaultProps;
export default ImagesPreview;
