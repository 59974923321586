import { createRoutine } from 'redux-saga-routines';

const setOrganization = createRoutine('ORGANIZATION_SET_NEW');
const setOrganizationRequired = createRoutine('ORGANIZATION_SET_REQUIRED');
const getRequiredWaterReading = createRoutine('ORGANIZATION_GET_REQUIRED_WATER_READING');
const setRequiredWaterReadingResult = createRoutine(
  'ORGANIZATION_SET_REQUIRED_WATER_READING_RESULT',
);

const getLogWaterReading = createRoutine('ORGANIZATION_GET_LOG_WATER_READING');

const setSelectedOrganization = items => ({
  type: 'ORGANIZATION_SET_SELECTED',
  payload: {
    items,
  },
});

export default {
  setOrganization,
  setOrganizationRequired,
  setSelectedOrganization,
  getRequiredWaterReading,
  setRequiredWaterReadingResult,
  getLogWaterReading,
};
