import React from 'react';
import PropTypes from 'prop-types';
import WrapperDefault from './WrapperDefault';
import './styles.css';

const propTypes = {
  children: PropTypes.func,
  type: PropTypes.oneOf(['default']),
  className: PropTypes.string,
  isAlignCenter: PropTypes.bool,
};

const defaultProps = {
  type: 'default',
  className: '',
  isAlignCenter: true,
};

const Content = ({ children, isAlignCenter, type, className }) => {
  const styleContentAlignCenter = isAlignCenter ? 'content_center' : '';
  return (
    <>
      <div className={`content_component${className.length ? ` ${className}` : ``}`}>
        {type === 'default' ? (
          <WrapperDefault extraStyle={styleContentAlignCenter} content={children} />
        ) : null}
      </div>
    </>
  );
};

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
